import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Segment, Dropdown } from "semantic-ui-react";
import { generateLucene } from "../utils/Helper";

class DataNotFoundModal extends React.Component<any> {
  state: any = { searchQuery: "", startDate: null, endDate: null };

  componentDidMount() {
    // console.log(this.props.preset);
    let presetList = this.props.preset.map((ele: any) => ({
      key: ele.searchName,
      text: ele.searchName,
      value: ele.searchText,
    }));
    this.setState({
      searchQuery: this.props.searchQuery,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      preset: presetList,
    });
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      let presetList = this.props.preset.map((ele: any) => ({
        key: ele.searchName,
        text: ele.searchName,
        value: ele.searchText,
      }));
      this.setState({
        searchQuery: this.state.searchQuery,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        preset: presetList,
      });
    }
  }
  render() {
    // console.log(this.state.searchQuery);
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{this.props.topic}</h4>
          <p>{this.props.description}</p>
          <Segment raised>
            Search Query Preset
            <Dropdown
              placeholder="Preset"
              fluid
              selection
              defaultValue={this.state.selectedPresetVal}
              value={this.state.selectedPresetVal}
              options={this.state.preset}
              onChange={(event, data) => {
                // this.setState({ selectedPresetVal: data.value });
                this.setState({ searchQuery: data.value });
              }}
              style={{ marginBottom: "10px" }}
            />
            <Input
              fluid
              focus
              placeholder="put AND/OR to for logical searching"
              onChange={(event) => {
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });

                let text = event.target.value;
                text = text.toLowerCase();
                text = text.replace(/ AND /gim, " AND ");
                text = text.replace(/ OR /gim, " OR ");
                this.setState({ searchQuery: text });
              }}
              value={this.state.searchQuery}
              style={{ marginBottom: "10px" }}
            />
            <div>{`start date :\n ${this.state.startDate}`}</div>
            <div
              style={{ marginTop: "10px" }}
            >{`end date :\n ${this.state.endDate}`}</div>
            <Button
              variant="secondary"
              onClick={() => {
                this.props.callBack2();
              }}
              style={{ marginTop: "10px" }}
            >
              Pick New Date Range
            </Button>
          </Segment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              this.state.searchQuery.length === 0 ||
              generateLucene(this.state.searchQuery) === "Invalid!!"
            }
            variant={this.props.button1Style}
            onClick={() => {
              this.props.setToggleShowDataNotFoundModal();
              this.props.callBack1(this.state.searchQuery, true);
            }}
          >
            {this.props.button1}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DataNotFoundModal;
