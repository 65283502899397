import React from "react";
import { connect } from "react-redux";
// import { addDays } from "date-fns";
import Loading from "./Loading";
import { getLoginUser } from "../actions";

import Login from "./Login";
import Dashboard from "./Dashboard";
import Operation from "../social-operation/components/operation";
import Home from "./Home";
import Manage from "./Manage";

class Main extends React.Component<any> {
  state = {
    isShowLoading: true,
  };

  componentDidMount() {
    // console.log(this.props.currentPage);
    this.props.getLoginUser();
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps.loginUser !== this.props.loginUser) {
      if (this.props.loginUser.status !== "Initializing") {
        this.setState({ isShowLoading: false });
      }
    }
    // console.log(this.props.currentPage);
  }
  renderLoading = () => {
    if (this.state.isShowLoading) {
      return <Loading />;
    }
    return null;
  };
  showLoading = () => {
    this.setState({ isShowLoading: true });
  };
  hideLoading = () => {
    this.setState({ isShowLoading: false });
  };
  renderContent = () => {
    if (this.props.loginUser.status !== "Initializing") {
      if (this.props.loginUser.data) {
        switch (this.props.currentPage) {
          case "Home":
            return (
              <Home
                showLoading={this.showLoading}
                hideLoading={this.hideLoading}
              />
            );
          case "Dashboard":
            return (
              <Dashboard
                showLoading={this.showLoading}
                hideLoading={this.hideLoading}
              />
            );
          case "Operation":
            return (
              <Operation
                showLoading={this.showLoading}
                hideLoading={this.hideLoading}
              />
          );
          case "Manage":
            return (
              <Manage
                showLoading={this.showLoading}
                hideLoading={this.hideLoading}
              />
            );
          default:
            break;
        }
      } else {
        return <Login></Login>;
      }
    } else {
      return null;
    }
  };

  render() {
    // console.log(this.props.loginUser);
    return (
      <div
        style={{ overflow: "auto", width: "100%", height: "100vh" }}
        id="renderContainer"
      >
        {this.renderLoading()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (states: any) => {
  return {
    currentPage: states.page.currentPage,
    loginUser: states.loginUser,
  };
};
export default connect(mapStateToProps, { getLoginUser })(Main);
