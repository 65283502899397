import { SAVE_SEARCH, GET_PRESET } from "../actions/types";

export default function (state = null, action: any) {
  switch (action.type) {
    case SAVE_SEARCH:
      return action.payload;
    case GET_PRESET:
      return action.payload;
    default:
      return state;
  }
}
