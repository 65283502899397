import React from "react";
// import { connect } from "react-redux";
import { Card, Label, Icon } from "semantic-ui-react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { Container, Row } from "react-bootstrap";

class SOV extends React.Component<any> {
  state = { isShowSuggest: false };
  [key: string]: any;

  componentDidMount() {
    this.ref = React.createRef();
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF88FE",
    "#00FFFE",
    "#8888FE",
  ];

  RADIAN = Math.PI / 180;

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
    const y = cy + radius * Math.sin(-midAngle * this.RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderPieChart = () => {
    const data = this.props.data.map((ele: any) => ({
      name: ele.name,
      value: ele.amt,
    }));
    return (
      <PieChart width={500} height={375} onMouseEnter={this.onPieEnter}>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Pie
          data={data}
          dataKey="value"
          cx={250}
          cy={150}
          labelLine={false}
          label={this.renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          onClick={(param) => {
            if (param) {
              const bigTableFilter = {
                related: param.name,
              };
              this.props.setToggleShowBigTableWithFilter(bigTableFilter);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {data.map((ele: any, index: any) => {
            return <Cell key={'cell-' + index} fill={this.props.colors[ele.name.toLowerCase()]} />;
          })}
        </Pie>
      </PieChart>
    );
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Share of voice (SOV)
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(this.ref, "SOV.jpeg", "#FFFFFF");
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>Share of voice (SOV)</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Container ref={this.ref}>
            <Row>{this.renderPieChart()}</Row>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}

export default SOV;
