import React from "react";
import _ from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Label as RCLabel,
  PieChart,
  Pie,
  Cell
} from "recharts";

import { Card, Label, Icon, Tab, Grid, Divider, Segment, Header, SemanticWIDTHS } from "semantic-ui-react";
import { Container, Row } from "react-bootstrap";
import { constSelector } from "recoil";

const SOURCE_COLORS: any = {
  facebook: "#2F329F",
  pantip: "#6D1C77",
  twitter: "#1D9BF0", //B8206A
}; //facebook,pantip,twitter

class OVerallBankSentiment extends React.Component<any> {
  RADIAN = Math.PI / 180;
  panes = [
    { menuItem: 'Type 1', render: () => <Tab.Pane>{this.renderBarChart_1()}</Tab.Pane> },
    { menuItem: 'Type 2', render: () => <Tab.Pane>{this.renderBarChart_2()}</Tab.Pane> },
    { menuItem: 'Type 3', render: () => <Tab.Pane>{this.renderBarChart_3()}</Tab.Pane> },
  ]

  state = { isShowSuggest: false, currentTab: 0, eachKeywordData: [], eachBankSentimentC1: [{}], dataTotalForC1: [{}], dataPieC2: [{}], dataPieEachSenC2: [{}], dataTotalForC2: [{}], dataTotalForC3: [{}], totalForC1: { "all": 0 } };
  [key: string]: any;
  componentDidMount() {
    this.ref = React.createRef();
    this.transformData()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      this.transformData()
    }
  }

  transformData = () => {
    const allSentiment = this.props.data[0].data.xLabels;
    const sentimentStat: Readonly<Array<Object>> = allSentiment.map((sentiment: any) => {
      let total: number = 0;
      this.props.data.forEach((src: any) => {
        const sentIdx = src.data.xLabels.indexOf(sentiment)
        src.data.data.forEach((bank: any) => {
          total += bank[sentIdx];
        })
      })
      return { "name": sentiment, "total": total }
    })
    const sumAll: number = _.sumBy(sentimentStat, "total")
    let total: { [key: string]: number } = { "all": sumAll }
    const allBanks: any = this.props.data[0].data.yLabels;
    const eachBankSentiment: Array<Object> = allBanks.map((bank: any) => {
      const tmpObj: any = []
      let totalAll: number = 0;
      allSentiment.forEach((sentiment: any) => {
        let total: number = 0;
        this.props.data.forEach((src: any) => {
          const bankIdx = src.data.yLabels.indexOf(bank)
          const sentIdx = src.data.xLabels.indexOf(sentiment)
          total += src.data.data[bankIdx][sentIdx];
        })
        tmpObj.push({ name: sentiment, total: total })
        totalAll += total
      })
      return {
        name: bank,
        data: tmpObj,
        amt: totalAll,
        color: this.props.colors[bank.toLowerCase()]
      }
    })

    const dataPieC2 = eachBankSentiment.map((bank: any) => {
      return { name: bank.name.toUpperCase(), value: bank.amt }
    })

    const dataPieEachSenC2 = allSentiment.map((sen: any) => {
      let retSen: any = { name: sen };
      let data: any = [];
      let total = 0;
      eachBankSentiment.forEach((bank: any) => {
        const tmp = bank.data.filter((ele: any) => (ele.name === sen))
        data.push({ name: bank.name, value: tmp[0].total })
        total += tmp[0].total;
      })
      retSen["data"] = data;
      retSen["amt"] = total;
      return retSen;
    })

    const dataTotalForC3 = dataPieEachSenC2.map((sen: any) => {
      let tmp: any = { name: sen.name };
      sen.data.forEach((bank: any) => {
        tmp[bank.name.toUpperCase()] = bank.value;
      })
      return tmp;
    })

    this.setState({ dataPieC2: dataPieC2, dataPieEachSenC2: dataPieEachSenC2, eachBankSentimentC1: eachBankSentiment, dataTotalForC1: sentimentStat, totalForC1: total, dataTotalForC3: dataTotalForC3 })
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderAllChartsC1 = (data: any) => {
    if (Object.keys(data[0]).length === 0) {
      return <div></div>
    }
    return data.map((ele: any, idx: number) => (<Grid.Column>
      <BarChart key={`Bar2_IDX${idx}`} width={100} height={300} data={ele.data} layout="vertical" margin={{
        top: 30,
        right: 30,
        left: 10,
        bottom: 30,
      }}
        style={{ cursor: "pointer" }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: ele.name,
              sentiment: param.activeLabel,
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}>
        <XAxis hide={false} type="number" axisLine={false} tickLine={false} tick={false}>
          <RCLabel value={`(${ele.amt})`} offset={-10} position="insideBottom" />
          <RCLabel value={ele.name.toUpperCase()} offset={220} position="top" />
        </XAxis>
        <YAxis hide={true} dataKey="name" type="category" />
        <Tooltip />
        <Bar dataKey="total" fill={ele.color} label={({ payload, x, y, width, height, value }: any) => {
          return <text x={x + width} y={y + 10 + height / 2} fill="#666" textAnchor="left" dy={-6}>{`${Math.round(value * 100 / ele.amt)}%`}</text>;
        }} />
      </BarChart>
    </Grid.Column>))
  }

  renderBarChart_1 = () => {
    return (
      <Grid textAlign='center' padded={false} divided style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width={5}>
            <ResponsiveContainer>
              <ComposedChart width={100} height={300} data={this.state.dataTotalForC1} layout="vertical" margin={{
                top: 30,
                right: 30,
                left: 10,
                bottom: 30,
              }}
                style={{ cursor: "pointer" }}
                onClick={(param) => {
                  if (param) {
                    const bigTableFilter = {
                      source: this.props.dispSources,
                      related: this.props.dispTargets,
                      sentiment: param.activeLabel,
                    };
                    this.props.setToggleShowBigTableWithFilter(bigTableFilter);
                  }
                }}>
                <XAxis hide={false} type="number" axisLine={false} tickLine={false} tick={false}>
                  <RCLabel value={`(${this.state.totalForC1["all"]})`} offset={-10} position="insideBottom" />
                  <RCLabel value="Total" offset={220} position="top" />
                </XAxis>
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Bar dataKey="total" fill="#b6bbc2" label={({ payload, x, y, width, height, value }: any) => {
                  return <text x={x + width} y={y + 10 + height / 2} fill="#666" textAnchor="left" dy={-6}>{`${Math.round(value * 100 / this.state.totalForC1["all"])}%`}</text>;
                }} />
              </ComposedChart>
            </ResponsiveContainer>
          </Grid.Column>

          <Grid.Column width={11}>
            <Grid columns={this.state.eachBankSentimentC1.length as SemanticWIDTHS} textAlign='left' padded={false} stackable>
              <Grid.Row>
                {this.renderAllChartsC1(this.state.eachBankSentimentC1)}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderAllChartsC2 = (data: any) => {
    return data.map((ele: any, idx: number) => (
      <Grid.Column>
        {ele.name}
        <PieChart width={200} height={375} onMouseEnter={this.onPieEnter} margin={{
          top: 35,
          right: 0,
          left: 0,
          bottom: 0,
        }}>
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Pie
            data={ele.data}
            dataKey="value"
            cx={100}
            cy={150}
            labelLine={false}
            label={this.renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            onClick={(param) => {
              if (param) {
                const bigTableFilter = {
                  related: param.name,
                  sentiment: [ele.name.toUpperCase()],
                  source: this.props.dispSources,
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {this.state.dataPieC2.map((ele: any, index: any) => {
              return <Cell key={'cell-' + index} fill={this.props.colors[ele.name.toLowerCase()]} />;
            })}
          </Pie>
        </PieChart>
        {`(${ele.amt})`}
      </Grid.Column>))
  }

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
    const y = cy + radius * Math.sin(-midAngle * this.RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderBarChart_2 = () => {
    return (
      <Grid textAlign='center' padded={false} divided style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width={5}>
            Total
            <PieChart width={300} height={375} onMouseEnter={this.onPieEnter} margin={{
              top: 35,
              right: 10,
              left: 10,
              bottom: 30,
            }}>
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Pie
                data={this.state.dataPieC2}
                dataKey="value"
                cx={130}
                cy={150}
                labelLine={false}
                label={this.renderCustomizedLabel}
                outerRadius={140}
                fill="#8884d8"
                onClick={(param) => {
                  if (param) {
                    const bigTableFilter = {
                      related: param.name,
                    };
                    this.props.setToggleShowBigTableWithFilter(bigTableFilter);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                {this.state.dataPieC2.map((ele: any, index: any) => {
                  return <Cell key={'cell-' + index} fill={this.props.colors[ele.name.toLowerCase()]} />;
                })}
              </Pie>
            </PieChart>
            {`(${this.state.totalForC1.all})`}
          </Grid.Column>

          <Grid.Column width={11}>
            <Grid columns={this.state.dataPieEachSenC2.length as SemanticWIDTHS} textAlign='center' padded={false} stackable>
              <Grid.Row style={{ width: "100%" }}>
                {this.renderAllChartsC2(this.state.dataPieEachSenC2)}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderAllChartsC3 = (data: any) => {
    return data.map((bank: any, idx: number) => {
      return (
        <Bar key={`Bar3_IDX${idx}`} dataKey={bank} fill={this.props.colors[bank.toLowerCase()]} />
      )
    })
  }

  renderBarChart_3 = () => {
    return (
      <BarChart
        width={1000}
        height={300}
        data={this.state.dataTotalForC3}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{ cursor: "pointer" }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: this.props.dispTargets,
              sentiment: param.activeLabel,
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {this.renderAllChartsC3(Object.keys(this.state.dataTotalForC3[0]).filter((ele) => (ele !== "name")))}
      </BarChart>
    );
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Overall Sentiment by Bank
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(
                    this.ref,
                    "overAllBankMention.jpeg",
                    "#FFFFFF"
                  );
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>Overall Sentiment by bank</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Container ref={this.ref}>
            <Row>
              <Tab
                panes={this.panes}
                activeIndex={this.state.currentTab}
                renderActiveOnly={true}
                onTabChange={(event: any, data: any) => {
                  this.setState({ currentTab: data.activeIndex });
                }}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                style={{ width: "100%" }}
              /></Row>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}

export default OVerallBankSentiment;
