import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { operationLoadingModal } from "../recoil-atoms/operation";

export const useLoadingModal = () => {
	const minLoadingDelay = 250;	// ms
	const opLoadingModal = useRecoilValue(operationLoadingModal);
	const [loadingDelayReached, setLoadingDelayReached] = useState(false);
	const [active, toggle] = useState(false);

	useEffect(() => {
		if (active) {
			opLoadingModal.showLoading();
			setLoadingDelayReached(false);
			setTimeout(() => setLoadingDelayReached(true), minLoadingDelay);
		}
		else if (!active && loadingDelayReached)
			opLoadingModal.hideLoading();
	}, [active, loadingDelayReached]);

	return {
		active: active,
		toggle: toggle
	};
}