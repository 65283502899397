import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import userReducer from "./userReducer";
import statDataReducer from "./statDataReducer";
import detailDataReducer from "./detailDataReducer";
import presetReducer from "./presetReducer";
import configReducer from "./configReducer";
import downloadCSVReducer from "./downloadCSVReducer";

export default combineReducers({
  page: pageReducer,
  loginUser: userReducer,
  statData: statDataReducer,
  detailData: detailDataReducer,
  preset: presetReducer,
  configuration: configReducer,
  csvDownloadURL: downloadCSVReducer,
});
