var parser = require("lucene-query-parser");

export function deepClone(listObj: any) {
  return listObj.map((obj: any) => {
    var temp: any = {};
    Object.keys(obj).forEach((key) => {
      temp[key] = obj[key];
    });
    return temp;
  });
}

export function capitalizeText(text: any) {
  const result = text[0].toUpperCase() + text.slice(1);
  return result;
}

function generateQueryString(term: any) {
  let leftText: any = "";
  let rightText: any = "";
  let allTerms = Object.keys(term);
  if (allTerms.indexOf("term") !== -1) {
    const check_space = new RegExp(".+\\s+.+", "is");
    let luceneTerm = "";
    if (check_space.test(term.term)) {
      luceneTerm = `"${term.term}"`;
    } else {
      luceneTerm = `"${term.term}"`;
    }
    return luceneTerm;
  } else {
    if (allTerms.indexOf("left") !== -1) {
      let opr = "";
      if (allTerms.indexOf("operator") !== -1) {
        opr = term.operator;
      }
      leftText = generateQueryString(term.left);
      if (allTerms.indexOf("right") !== -1) {
        rightText = generateQueryString(term.right);
      }
      if (rightText !== "") return `(${leftText} ${opr} ${rightText})`;
      else return `(${leftText})`;
    }
  }
}

export function generateLucene(searchQuery: any) {
  // console.log(searchQuery);
  let lucene: any = "";
  try {
    const parserResult = parser.parse(searchQuery);
    // console.log(parserResult);
    lucene = generateQueryString(parserResult);
  } catch (e) {
    lucene = "Invalid!!";
  }

  return lucene;
}
