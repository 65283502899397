import React from "react";

// Components
import { Container, Header, Icon, Segment, Tab } from "semantic-ui-react";

// 3rd-Party Actions (Custom Hooks, States, etc.)

// Styles
import '../css/operation.css';

export const ViewAgents = () => {
	return (
		<Tab.Pane as={Container}>
			<Segment placeholder>
				<Header as="h1" icon>
					<Icon name="exclamation triangle"/>
					Soon™
				</Header>
			</Segment>
		</Tab.Pane>
	);
};