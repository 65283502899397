export const stackAtomPush = (
	getter: any[],
	setter: Function,
	value: any | any[]
) => {
	let stack: any[] = getter.map((e: any) => e);
	
	if (!Array.isArray(value))
		stack.push(value);
	else
		stack.concat(value);
	setter(stack);
}
export const stackAtomPop = (
	getter: any,
	setter: Function,
	entries: (number | 'all') = 1
) => {
	let stack: any[] = getter.map((e: any) => e);
	
	if (entries === 'all') {
		setter([]);
		return stack;
	}

	let poppedStack: any[] = stack.splice(-entries);
	setter(stack);
	return poppedStack;
}
export const stackAtomPeek = (getter: any) => getter.length ? getter[getter.length - 1] : null;