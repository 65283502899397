import React from "react";

class Loading extends React.Component {
  render() {
    return (
      <div className="ui dimmer modals visible active">
        <div className="content">
          <div className="ui massive text loader">Now Loading</div>
        </div>
      </div>
    );
  }
}

export default Loading;
