export const toDateString = (date: Date) => date.toISOString().substring(0, 10);
export const getRemainingSLAString = (seconds: number) => {
	let h: string = Math.floor(seconds / 3600).toString().padStart(1, '0');
	let m: string = Math.floor((seconds / 60) % 60).toString().padStart(2, '0');
	let s: string = Math.floor(seconds % 60).toString().padStart(2, '0');
	let str: string = '';

	str += h ? h + ':' : '';
	str += m ? m + ':' : '';
	str += s ? s : '';

	return str;
};