import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Icon, Label } from "semantic-ui-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from "recharts";

class BigAggLineChart extends React.Component<any> {
  state = { isShowSuggest: false };
  [key: string]: any;

  componentDidMount() {
    this.ref = React.createRef();
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };
  render() {
    return (
      <Modal
        show={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ width: "100%" }}
          >
            Total Mention Count
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(this.ref, "AggLine.jpeg", "#FFFFFF");
                }}
              />
              <Icon
                name="compress"
                size="small"
                style={{ cursor: "pointer" }}
                onClick={this.props.setToggleShowBigAggLineChart}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <div ref={this.ref} style={{ width: "100%", height: "100%" }}>
          <LineChart
            width={1100}
            height={580}
            data={this.props.data}
            margin={{
              top: 20,
              right: 40,
              left: 40,
              bottom: 50,
            }}
            style={{ cursor: "pointer" }}
            onClick={(param) => {
              if (param) {
                const bigTableFilter = {
                  overide_datetime: param.activeLabel.split("+")[0],
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);
              }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Tick" minTickGap={10} hide={false} />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(this.props.colors).map((key) => {
              return (
                <Line
                  key={`aggLine_${key}`}
                  type="monotone"
                  dataKey={key}
                  stroke={this.props.colors[key]}
                />
              );
            })}
            <Brush
              dataKey="Tick"
              height={30}
              stroke="#000000"
              y={540}
              // startIndex={10}
              // endIndex={20}
            >
              <LineChart>
                {Object.keys(this.props.colors).map((key) => {
                  return (
                    <Line
                      key={`brushLine_${key}`}
                      type="monotone"
                      dataKey={key}
                      stroke={this.props.colors[key]}
                    />
                  );
                })}
              </LineChart>
            </Brush>
          </LineChart>
        </div>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={this.props.setToggleShowBigAggLineChart}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BigAggLineChart;
