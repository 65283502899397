import React from "react";
// import { connect } from "react-redux";
import { FacebookProvider, EmbeddedPost } from "react-facebook";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { ReactTinyLink } from "react-tiny-link";
import { Divider, Label, Grid, Icon } from "semantic-ui-react";

class CustomerEngagement extends React.Component<any> {
  state: any = {
    data: {},
    isShowSuggest: false,
  };
  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      this.setState({ data: this.props.data });
    }
  }

  renderClickToExpandSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="small" pointing="right">
          Click to expand the chart
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderEmbedFacebook = () => {
    return this.state.data.facebook.destList.map((ele: any, idx: any) => {
      return (
        <Grid.Column>
          <Icon name="signal"></Icon>
          {this.state.data.facebook.scoreList[idx]}
          <EmbeddedPost key={`embedFacebook_${idx}`} href={ele} width="350" />
        </Grid.Column>
      );
    });
  };

  renderEmbedTwitter = () => {
    return this.state.data.twitter.destList.map((ele: any, idx: any) => {
      return (
        <Grid.Column>
          <Icon name="signal"></Icon>
          {this.state.data.facebook.scoreList[idx]}
          <TwitterTweetEmbed key={`embedTwitter_${idx}`} tweetId={ele} />
        </Grid.Column>
      );
    });
  };

  renderEmbedPantip = () => {
    return this.state.data.pantip.destList.map((ele: any, idx: any) => {
      return (
        <Grid.Column style={{ width: "330px", margin: "10px" }}>
          <Icon name="signal"></Icon>
          {this.state.data.facebook.scoreList[idx]}
          <ReactTinyLink
            key={`embedPantip_${idx}`}
            cardSize="large"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={ele}
          />
        </Grid.Column>
      );
    });
  };

  render() {
    if (
      this.state.data.facebook &&
      this.state.data.twitter &&
      this.state.data.pantip
    ) {
      return (
        <div>
          <Icon size="huge" name="facebook"></Icon>
          <FacebookProvider appId="172862490790271">
            <Grid divided="vertically" style={{ marginTop: "15px" }}>
              <Grid.Row columns={3}>{this.renderEmbedFacebook()}</Grid.Row>
            </Grid>
          </FacebookProvider>
          <Divider></Divider>
          <Icon size="huge" name="twitter"></Icon>
          <Grid divided="vertically" style={{ marginTop: "15px" }}>
            <Grid.Row columns={3}>{this.renderEmbedTwitter()}</Grid.Row>
          </Grid>
          <Divider></Divider>
          <img
            src="/image/pantip.png"
            alt="InnoHub"
            style={{
              width: "50px",
              height: "50px",
              display: "inline",
              marginLeft: "auto",
              marginRight: "auto",
              filter: "grayscale(100%)",
            }}
          ></img>
          <Grid.Row columns={3} style={{ marginTop: "15px" }}>
            {this.renderEmbedPantip()}
          </Grid.Row>
        </div>
      );
    }
    return null;
  }
}

export default CustomerEngagement;
