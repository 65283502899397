import React from "react";
import history from "../history";
import { Router, Route, Switch } from "react-router-dom";
import "../css/index.css";
// import { Container } from "react-bootstrap";
import Header from "./Header";
import Main from "./Main";

const App = () => {
  return (
    //<Switch> help to show only one route at a time
    <div className="canvas">
      <Header />
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Main} />
          {/* <Route path="/dashboard" exact component={Dashboard} /> */}
        </Switch>
      </Router>
    </div>
  );
};

export default App;
