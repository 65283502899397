export const CHANGE_PAGE = "CHANGE_PAGE";
export const GET_LOGIN_USER = "GET_LOGIN_USER";
export const GET_STAT_DATA = "GET_STAT_DATA";
export const GET_DETAIL_DATA = "GET_DETAIL_DATA";
export const SAVE_SEARCH = "SAVE_SEARCH";
export const GET_PRESET = "GET_PRESET";
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const SAVE_EDITPRESET = "SAVE_EDITPRESET";
export const SAVE_HUMAN_LABEL = "SAVE_HUMAN_LABEL";
export const GET_DOWNLOAD_URL = "GET_DOWNLOAD_URL";
