import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError } from "../models/error-http";
import { SocialPostRequestInterface, SocialPostResponseInterface } from "../models/operation";
import { toDateString } from "../utils/string-formatters";

// not sure if this was P'Aek's intention...
interface WrappedSocialPostResponseInterface {
	responsePayload: SocialPostResponseInterface
}

export const getPosts = async (payload: SocialPostRequestInterface): Promise<SocialPostResponseInterface> => {
	if (payload.from instanceof Date)
		payload.from = toDateString(payload.from);
	if (payload.to instanceof Date)
		payload.to = toDateString(payload.to);

	const response = await axios.post<WrappedSocialPostResponseInterface>(`/api/getPosts`, payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ` + response.status);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data.responsePayload;
};