import React from "react";
// import { connect } from "react-redux";
import { HeatMapGrid as HeatMap } from "react-grid-heatmap";
import { Card, Header, Segment, Icon, Label } from "semantic-ui-react";
import { capitalizeText } from "../../utils/Helper";

class Heatmap extends React.Component<any> {
  state = { isShowSuggest: false };
  [key: string]: any;

  componentDidMount() {
    this.ref = React.createRef();
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  hexToRgb(hex: any) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  renderHeatmap = () => {
    const sentimentColor: any = {
      positive: "#34a321",
      negative: "#b3072c",
      neutral: "#7d7b72",
    };
    return this.props.data.map((ele: any, idx: any) => {
      return (
        <Segment key={`HM_${idx}`}>
          <Header as="h4">{capitalizeText(ele.source)}</Header>
          <HeatMap
            xLabels={ele.data.xLabels}
            yLabels={ele.data.yLabels.map((ele: any) => ele.toUpperCase())}
            data={ele.data.data}
            onClick={(y: any, x: any) => {
              const bigTableFilter = {
                source: ele.source,
                sentiment: ele.data.xLabels[x],
                related: ele.data.yLabels[y],
              };
              this.props.setToggleShowBigTableWithFilter(bigTableFilter);
            }}
            cellStyle={(y, x, ratio) => {
              const hexColor = sentimentColor[ele.data.xLabels[x]];
              const rgb: any = this.hexToRgb(hexColor);
              return {
                background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${ratio})`,
                fontSize: "11.5px",
                color: "#000"
              }
            }}
            cellRender={(x: any, y: any, value: any) => value && `${value}`}
            cellHeight="2.0rem"
          />
        </Segment>
      );
    });
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Heat Map
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(this.ref, "Heatmap.jpeg", "#FFFFFF");
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>
            Heat map of each source, target and sentiment
          </Card.Meta>
        </Card.Content>
        <Card.Content>
          <div ref={this.ref} style={{ width: "100%", height: "100%" }}>
            {this.renderHeatmap()}
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default Heatmap;
