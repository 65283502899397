import React from "react";
// import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import {
  Icon,
  Button,
  Segment,
  Step,
  Dropdown,
  Input,
  Checkbox,
} from "semantic-ui-react";
import { generateLucene } from "../utils/Helper";

const timeFrameOption = [
  { key: "1m", text: "1 minute", value: "1m" },
  { key: "1H", text: "1 hour", value: "1H" },
  { key: "1D", text: "1 day", value: "1D" },
  { key: "1W", text: "1 week", value: "1w" },
  { key: "1M", text: "1 month", value: "1M" },
  { key: "1Y", text: "1 year", value: "1y" },
];

class ControlPanel extends React.Component<any> {
  state: any = {
    preset: null,
  };

  componentDidMount() {
    let presetList = this.props.preset.map((ele: any) => ({
      key: ele.searchName,
      text: ele.searchName,
      value: ele.searchText,
    }));
    this.setState({
      preset: presetList,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      let presetList = this.props.preset.map((ele: any) => ({
        key: ele.searchName,
        text: ele.searchName,
        value: ele.searchText,
      }));
      this.setState({
        preset: presetList,
      });
    }
  }

  onSearchKeywordsChange = (event: any) => {
    const caret = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    let text = event.target.value;
    text = text.toLowerCase();
    text = text.replace(/ AND /gim, " AND ");
    text = text.replace(/ OR /gim, " OR ");
    this.props.setQuery(text);
  };

  searchQuery = () => {
    this.props.exeSearchQuery(
      this.props.isBankQuery ? this.props.searchQuery : this.props.nonBankQuery
    );
  };

  renderSearchBox = () => {
    if (this.props.isBankQuery) {
      return (
        <Input
          placeholder="Enter search query here... (keyword1 AND/OR keyword2, ...)"
          onChange={this.onSearchKeywordsChange}
          value={this.props.searchQuery}
          icon={
            <Icon
              name="save"
              inverted
              circular
              link
              onClick={this.props.setToggleShowSaveQueryModal}
              disabled={
                this.props.searchQuery.length === 0 ||
                this.props.searchLucene === "Invalid!!"
                  ? true
                  : false
              }
            />
          }
          fluid
        />
      );
    } else {
      let nonBankSearchInput: any = [];
      this.props.nonBankQuery.forEach((element: any, i: any) => {
        nonBankSearchInput.push(
          <Row key={`nonBankSearchInput_${i}`} style={{ marginTop: "2px" }}>
            <Col lg={1} md={12} xs={12}>
              <Checkbox
                checked={this.props.nonBankQuery[i][4]}
                id={`filterCheckbox${i}`}
                style={{ marginTop: "2px" }}
                onClick={(event, data) => {
                  let tmpList = this.props.nonBankQuery;
                  tmpList[i][4] = data.checked;
                  this.props.setNonBankQuery(tmpList);
                }}
              />
            </Col>
            <Col lg={4} md={12} xs={12}>
              <Input
                fluid
                size="mini"
                label={`Group name ${i}`}
                placeholder="Group name"
                value={element[0]}
                onChange={(event) => {
                  let tmpList = this.props.nonBankQuery;
                  tmpList[i][0] = event.target.value;
                  this.props.setNonBankQuery(tmpList);
                }}
              />
            </Col>
            <Col lg={6} md={12} xs={12}>
              <Input
                error={!(element[3] === "Valid")}
                fluid
                size="mini"
                label={`Query ${i}`}
                placeholder="Query"
                value={element[1]}
                onChange={(event) => {
                  const element = event.target;
                  const caret = event.target.selectionStart;
                  window.requestAnimationFrame(() => {
                    element.selectionStart = caret;
                    element.selectionEnd = caret;
                  });
                  let text = event.target.value;
                  text = text.toLowerCase();
                  text = text.replace(/ AND /gim, " AND ");
                  text = text.replace(/ OR /gim, " OR ");
                  let tmpList = this.props.nonBankQuery;
                  tmpList[i][1] = text;
                  tmpList[i][2] = generateLucene(text);
                  tmpList[i][3] =
                    tmpList[i][2] === "Invalid!!" ? "Invalid!!" : "Valid";
                  this.props.setNonBankQuery(tmpList);
                  this.props.setSearchLucene(
                    this.checkAllNonBankQueryValid(this.props.nonBankQuery) !==
                      "Invalid!!"
                      ? tmpList[i][2]
                      : "Invalid!!"
                  );
                }}
                onFocus={(event: any) => {
                  let text = event.target.value;
                  this.props.setSearchLucene(
                    this.checkAllNonBankQueryValid(this.props.nonBankQuery) !==
                      "Invalid!!"
                      ? element[2]
                      : "Invalid!!"
                  );
                }}
              />
            </Col>
            <Col lg={1} md={12} xs={12}>
              {this.props.nonBankQuery.length > 1 ? (
                <Icon
                  name="minus square"
                  size="large"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let tmpList = this.props.nonBankQuery;
                    tmpList.splice(i, 1);
                    this.props.setNonBankQuery(tmpList);
                    this.props.setSearchLucene(
                      this.checkAllNonBankQueryValid(tmpList) !== "Invalid!!"
                        ? this.props.nonBankQuery[0][2]
                        : "Invalid!!"
                    );
                  }}
                />
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
        );
      });
      return (
        <Container>
          {nonBankSearchInput}
          <Row style={{ marginTop: "2px" }}>
            <Col lg={12} md={12} xs={12}>
              <Icon
                name="plus square"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let tmpList = this.props.nonBankQuery;
                  tmpList.push([
                    `Group ${tmpList.length + 1}`,
                    `Query`,
                    `Query`,
                    "Valid",
                    true,
                  ]);
                  this.props.setNonBankQuery(tmpList);
                }}
              />
              <Icon
                name="save"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={this.props.setToggleShowSaveQueryModal}
                disabled={
                  this.props.searchQuery.length === 0 ||
                  this.props.searchLucene === "Invalid!!"
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }
  };

  checkAllNonBankQueryValid = (nonBankQuery: any) => {
    let isAllValid = nonBankQuery.every((ele: any) => {
      return ele[3] === "Valid";
    });
    if (isAllValid) {
      this.props.setNonBankQuery(this.props.nonBankQuery);
    }
    return isAllValid ? "Valid" : "Invalid!!";
  };

  renderSearch = () => (
    <div>
      <Row>
        <Col lg={9} md={9} xs={12}>
          Search query (Put AND/OR for logical search query)
          {this.renderSearchBox()}
          <Checkbox
            checked={!this.props.isBankQuery}
            id="nonBankCheckbox"
            onClick={(event, data) => {
              let tmpLucene = "";
              if (!data.checked) {
                tmpLucene = generateLucene(this.props.searchQuery);
              } else {
                if (
                  this.checkAllNonBankQueryValid(this.props.nonBankQuery) ===
                  "Valid"
                ) {
                  tmpLucene = generateLucene(this.props.nonBankQuery[0][1]);
                } else {
                  tmpLucene = "Invalid!!";
                }
              }
              this.props.setIsBankQueryFlag(!this.props.isBankQuery);
              this.props.setSearchLucene(tmpLucene);
            }}
            style={{ marginTop: "2px" }}
          />
          Activate non-bank search
        </Col>
        <Col lg={3} md={3} xs={12}>
          Search Query Preset
          <Dropdown
            placeholder="Preset"
            fluid
            selection
            defaultValue={this.props.selectedPresetVal}
            value={this.props.selectedPresetVal}
            options={this.state.preset}
            onChange={(event, data) => {
              let selectedIndex = 0;
              this.state.preset.forEach((ele: any, index: any) => {
                if (ele.value === data.value) {
                  selectedIndex = index;
                }
              });
              let queries = (data.value as string).split("|");
              if (queries.length > 1) {
                //NonBank
                let tmpNoQuery: any[] = [];
                queries.slice(0, queries.length - 1).forEach((ele: any) => {
                  let group = ele.split(":");
                  tmpNoQuery.push([
                    group[1],
                    group[0],
                    generateLucene(group[0]),
                    "Valid",
                    true,
                  ]);
                });
                // this.setState({
                //   isBankQuery: false,
                //   nonBankQuery: tmpNoQuery,
                //   searchLucene: tmpNoQuery[2],
                // });
                this.props.setIsBankQueryFlag(false);
                this.props.setSelectedPresetVal(selectedIndex, data.value);
                this.props.selectPreset(tmpNoQuery, false);
                this.props.setNonBankQuery(tmpNoQuery);
                this.props.setSearchLucene(tmpNoQuery[2]);
              } else {
                this.props.setIsBankQueryFlag(true);
                this.props.setSelectedPresetVal(selectedIndex, data.value);
                // this.setState({
                //   selectedPresetVal: data.value,
                // });
                this.props.selectPreset(data.value, true);
              }
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "2px" }}>
        <Col lg={9} md={9} xs={12}>
          Operation
          <Segment style={{ color: "#89f536" }} size={"mini"} inverted>
            {this.props.searchLucene}
          </Segment>
        </Col>
        <Col lg={3} md={3} xs={12}>
          Time Frame
          <Dropdown
            placeholder="Time Frame"
            fluid
            selection
            defaultValue={timeFrameOption[2].value}
            options={timeFrameOption}
            onChange={(event, data) => {
              this.props.setTimeFrame(data.value);
            }}
          />
        </Col>
      </Row>
    </div>
  );

  renderDatePicker = () => (
    <Row style={{ marginTop: "10px" }}>
      <Col lg={8} md={8} xs={12}>
        <Step.Group size="small" widths={2}>
          <Step active>
            <Step.Content>
              <Step.Title>
                <Icon name="hourglass start" />
                Start date
              </Step.Title>
              <Step.Description>
                {this.props.startDate.toString()}
              </Step.Description>
            </Step.Content>
          </Step>
          <Step active>
            <Step.Content>
              <Step.Title>
                <Icon name="hourglass end" />
                End date
              </Step.Title>
              <Step.Description>
                {this.props.endDate.toString()}
              </Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Col>
      <Col lg={2} md={2} xs={12}>
        <Button
          icon
          primary
          style={{ width: "100%", height: "100%" }}
          onClick={this.props.setToggleCalendar}
        >
          <Icon size="large" name="calendar alternate outline" />
          Pick Date Range
        </Button>
      </Col>
      <Col lg={2} md={2} xs={12}>
        <Button
          icon
          positive
          style={{ width: "100%", height: "100%" }}
          onClick={this.searchQuery}
          disabled={
            this.props.searchQuery.length === 0 ||
            this.props.searchLucene === "Invalid!!"
              ? true
              : false
          }
        >
          <Icon size="large" name="search" />
          Search
        </Button>
      </Col>
    </Row>
  );

  renderFilter = () => {
    return (
      <div>
        <Row style={{ marginTop: "15px" }}>
          <Col lg={3} md={3} xs={3}>
            Sources
            <Dropdown
              placeholder="Source"
              fluid
              multiple
              search
              selection
              value={this.props.dispSources}
              options={this.props.sourceOptions}
              onChange={(event, data) => {
                this.props.setDispSources(data.value);
              }}
            />
          </Col>
          <Col lg={3} md={3} xs={3}>
            Sentiments
            <Dropdown
              placeholder="Sentiment"
              fluid
              multiple
              search
              selection
              value={this.props.dispSentiments}
              options={this.props.sentimentOptions}
              onChange={(event, data) => {
                this.props.setDispSentiments(data.value);
              }}
            />
          </Col>
          <Col lg={3} md={3} xs={3}>
            {this.props.isBankQuery ? (
              <div>
                Banks
                <Dropdown
                  placeholder="Bank"
                  fluid
                  multiple
                  search
                  selection
                  value={this.props.dispTargets}
                  options={this.props.bankOptions}
                  onChange={(event, data) => {
                    this.props.setDispTargets(data.value);
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
          </Col>
          <Col lg={3} md={3} xs={3} style={{ marginTop: "20px" }}>
            <Button
              icon
              secondary
              style={{ width: "100%" }}
              onClick={() => {
                this.props.setToggleShowConfirmModal();
              }}
            >
              <Icon size="small" name="save" />
              Save As Image
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={6}>
            Channel
            <Dropdown
              placeholder="Channel"
              fluid
              multiple
              search
              selection
              value={this.props.dispChannels}
              options={this.props.channelOptions}
              onChange={(event, data) => {
                this.props.setDispChannels(data.value);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    if (this.state.preset) {
      return (
        <Segment placeholder>
          <Container>
            {this.renderSearch()}
            {this.renderDatePicker()}
            {this.renderFilter()}
          </Container>
        </Segment>
      );
    }
    return null;
  }
}

export default ControlPanel;
