import _ from "lodash";
// const z = require("zebras");

// export const getWordcloudData = (raw) => {
//   const splitRaw = raw.result.flatMap((ele) => {
//     return ele.tokenize
//       .split(" ")
//       .map((ele1) => ({ text: ele1, value: 1 }))
//       .filter((ele1) => ele1.text !== "");
//   });
//   //   console.log(splitRaw);
//   let allTermCount = z.gbSum(
//     "value",
//     z.groupBy((d) => d.text, splitRaw)
//   );
//   //   console.log(allTermCount);
//   const tokenizedDoc = raw.result
//     .map((ele) => ele.tokenize.split(" "))
//     .filter((ele) => ele !== "");
//   let tfIdf = allTermCount.map((ele) => {
//     let df = tokenizedDoc.reduce(
//       (acc, ele1) => acc + (ele1.indexOf(ele.group) !== -1 ? 0 : 1),
//       0
//     );
//     return {
//       text: ele.group,
//       tfIdf: (ele.sum / splitRaw.length) * Math.log(tokenizedDoc.length / df),
//     };
//   });
//   tfIdf = z.sortByCol("tfIdf", "desc", tfIdf).slice(0, 500);
//   //   console.log(tfIdf);
//   let wordCloud = z.merge(tfIdf, allTermCount, "text", "group");
//   wordCloud = wordCloud.map((ele) => ({ text: ele.text, value: ele.sum }));
//   return wordCloud;
// };

export const getWordcloudData = (raw: any) => {
  let tmp = [];
  tmp = raw.wordcloud.map((ele: any) => ({ value: ele.text, count: ele.value }));
  return tmp;
};

// export const getHashTag = (raw) => {
//   const splitRaw = raw.result.flatMap((ele) => {
//     return ele.hashtag
//       .split(" ")
//       .map((ele1) => ({ text: ele1, value: 1 }))
//       .filter((ele1) => ele1.text !== "");
//   });
//   //   console.log(splitRaw);
//   let allTermCount = z.gbSum(
//     "value",
//     z.groupBy((d) => d.text, splitRaw)
//   );
//   //   console.log(allTermCount);
//   const tokenizedDoc = raw.result
//     .map((ele) => ele.tokenize.split(" "))
//     .filter((ele) => ele !== "");
//   let tfIdf = allTermCount.map((ele) => {
//     let df = tokenizedDoc.reduce(
//       (acc, ele1) => acc + (ele1.indexOf(ele.group) !== -1 ? 0 : 1),
//       0
//     );
//     return {
//       text: ele.group,
//       tfIdf: (ele.sum / splitRaw.length) * Math.log(tokenizedDoc.length / df),
//     };
//   });
//   tfIdf = z.sortByCol("tfIdf", "desc", tfIdf).slice(0, 500);
//   //   console.log(tfIdf);
//   let wordCloud = z.merge(tfIdf, allTermCount, "text", "group");
//   wordCloud = wordCloud.map((ele) => ({ text: ele.text, value: ele.sum }));
//   return wordCloud;
// };
export const getHashTag = (raw: any) => {
  let tmp = [];
  tmp = raw.hashtag.map((ele: any) => ({ value: ele.text, count: ele.value }));
  return tmp;
};

export const getRawAgg = (raw: any) => {
  let yLabels: any = [];
  let xLabels: any = [];
  let sources: any = [];
  Object.keys(raw.heatmap).forEach((key) => {
    yLabels.push(key);

    const checkSources = _.keys(raw.heatmap[key]);
    checkSources.forEach((ele1) => {
      if (sources.indexOf(ele1) === -1) {
        sources.push(ele1);
        const checkSentiment = _.keys(raw.heatmap[key][ele1]);
        checkSentiment.forEach((ele2) => {
          if (xLabels.indexOf(ele2) === -1) {
            xLabels.push(ele2);
          }
        });
      }
    });
  });
  const heatmap = sources.map((source: any) => {
    const hmap = yLabels.map((bank: any) => {
      return xLabels.map((sentiment: any) => {
        try {
          return raw.heatmap[bank][source][sentiment];
        } catch (err) {
          return 0;
        }
        // return raw.heatmap[bank][source][sentiment]
        //   ? raw.heatmap[bank][source][sentiment]
        //   : 0; // + Math.floor(Math.random() * 100)); //<-----delete random
      });
    });
    let data = { data: hmap, xLabels: xLabels, yLabels: yLabels };
    return { source: source, data: data };
  });
  return heatmap;
};

export const getDataAggTimeSeries = (raw: any) => {
  let aggTimeSeries: any = {};
  Object.keys(raw.mention_count).forEach((bank_key) => {
    raw.mention_count[bank_key].forEach((tick: any) => {
      if (typeof aggTimeSeries[tick.post_date] === "undefined") {
        aggTimeSeries[tick.post_date] = {};
      }
      aggTimeSeries[tick.post_date]["Tick"] = tick.post_date;
      aggTimeSeries[tick.post_date][bank_key] = tick.count;
    });
  });

  aggTimeSeries = Object.keys(aggTimeSeries).map(
    (tick_key) => aggTimeSeries[tick_key]
  );
  return aggTimeSeries;
};

export const getDataSentimentTimeSeries = (raw: any) => {
  let sentiment: any = {};
  Object.keys(raw.sentiment_count).forEach((bank_key) => {
    raw.sentiment_count[bank_key].forEach((tick: any) => {
      if (typeof sentiment[tick.sentiment] === "undefined") {
        sentiment[tick.sentiment] = {};
      }
      if (typeof sentiment[tick.sentiment][tick.post_date] === "undefined") {
        sentiment[tick.sentiment][tick.post_date] = {};
      }
      sentiment[tick.sentiment][tick.post_date]["Tick"] = tick.post_date;
      sentiment[tick.sentiment][tick.post_date][bank_key] = tick.count;
    });
  });

  Object.keys(sentiment).forEach((sentiment_key) => {
    sentiment[sentiment_key] = Object.keys(sentiment[sentiment_key]).map(
      (tick_key) => sentiment[sentiment_key][tick_key]
    );
  });
  return sentiment;
};

export const getRawTableData = (raw: any) => {
  const tableData = raw.result.map((ele: any, idx: any) => {
    return {
      id: idx,
      related: ele.related,
      date: ele.post_date,
      sentiment: ele.sentiment,
      source: ele.src,
      text: ele.text,
      hashtag: ele.hashtag,
      src_id: ele.src_id,
      src_ids: ele.src_ids,
      doc_id: ele.doc_id,
      user_label: ele.user_label,
      old_sentiment: ele.old_sentiment,
      cat_product: null,
      cat_channel: (ele.category && ele.category.length !== 0)
        ? ele.category.channel
        : "",
      complain: false,
    };
  });
  return tableData;
};

export const getDataSentiment = (rawData: any) => {
  let raw = _.cloneDeep(rawData);
  const sentimentData = Object.keys(raw.heatmap).map((bank, idx) => {
    let data01: any = [];
    let data02: any = [];
    let allSentiment: any = [];
    Object.keys(raw.heatmap[bank]).forEach((src) => {
      Object.keys(raw.heatmap[bank][src]).forEach((sentiment) => {
        if (allSentiment.indexOf(sentiment) === -1) {
          allSentiment.push(sentiment);
        }
      });
    });
    allSentiment.forEach((ele: any) => {
      let value = 0;
      Object.keys(raw.heatmap[bank]).forEach((src) => {
        value += raw.heatmap[bank][src][ele];
        data02.push({ name: src, value: raw.heatmap[bank][src][ele] });
      });
      data01.push({ name: ele, value: value });
    });
    return {
      bank: bank,
      data01: data01,
      data02: data02,
    };
  });
  return sentimentData;
};

//////For doughnut chart
// export const sumAllMentionsEachBank = (rawData) => {
//   let raw = _.cloneDeep(rawData);
//   let data01 = [];
//   let data02 = [];
//   Object.keys(raw.heatmap).forEach((bank) => {
//     let valueBank = 0;
//     Object.keys(raw.heatmap[bank]).forEach((src) => {
//       let valueSrc = 0;
//       Object.keys(raw.heatmap[bank][src]).forEach((sentiment) => {
//         valueSrc += raw.heatmap[bank][src][sentiment];
//       });
//       valueBank += valueSrc;
//       data02.push({ name: src, value: valueSrc, parent: bank });
//     });
//     data01.push({ name: bank, value: valueBank });
//   });
//   return {
//     data01: data01,
//     data02: data02,
//   };
// };

export const sumAllMentionsEachBank = (rawData: any) => {
  let raw = _.cloneDeep(rawData);
  let data: any = [];
  Object.keys(raw.heatmap).forEach((bank) => {
    let valueBank = 0;
    let tmpObj: any = {};
    Object.keys(raw.heatmap[bank]).forEach((src) => {
      let valueSrc = 0;
      Object.keys(raw.heatmap[bank][src]).forEach((sentiment) => {
        valueSrc += raw.heatmap[bank][src][sentiment];
      });
      valueBank += valueSrc;
      tmpObj[src] = valueSrc;
    });
    tmpObj["name"] = bank.toUpperCase();
    tmpObj["amt"] = valueBank;
    data.push(tmpObj);
  });
  return data;
};

export const getEv = (raw: any) => {
  let facebook: any = { destList: [], scoreList: [] };
  if (raw.ev.facebook) {
    raw.ev.facebook.forEach((ele: any) => {
      facebook.destList.push(ele.url);
      facebook.scoreList.push(ele.ev);
    });
  }

  let twitter: any = { destList: [], scoreList: [] };
  if (raw.ev.twitter) {
    raw.ev.twitter.forEach((ele: any) => {
      const url_split = ele.url.split("/");
      const split_len = url_split.length;
      twitter.destList.push(url_split[split_len - 1]);
      twitter.scoreList.push(ele.ev);
    });
  }
  let pantip: any = { destList: [], scoreList: [] };
  if (raw.ev.pantip) {
    raw.ev.pantip.forEach((ele: any) => {
      pantip.destList.push(ele.url);
      pantip.scoreList.push(ele.ev);
    });
  }
  return { facebook, twitter, pantip };
};

export const getOverallMentionCat = (raw: any) => {
  // let tmp = [...raw.channel];
  // let splitName = [];
  // tmp.forEach((element) => {
  //   splitName = element.name.split("->");
  //   _.set(element, "name", splitName.slice(1, splitName.length).join("->"));
  // });
  const cat = { product: raw.product, channel: raw.channel };
  const cat_bank_sen = { product: raw.product, channel: raw.channel_by_bank_by_sentiment };
  const cat_sen = { product: raw.product, channel: raw.channel_by_sentiment };
  const data = { cat: cat, cat_bank_sen: cat_bank_sen, cat_sen: cat_sen }
  return data;
};
