import { useState } from "react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getPosts as APIGetPosts } from "../../actions/getPosts";
import { MOCKED_POSTS } from "../../configs/mocked-posts";
import { SOCIAL_PLATFORMS } from "../../configs/operation";
import { SocialPostRequestInterface, SocialPostResponseInterface } from "../../models/operation";
import { operationPostsRequest, operationPostsResponse } from "../../recoil-atoms/operation";
import { CloneAndAssign } from "../../utils/object-helpers";
import { toDateString } from "../../utils/string-formatters";

export const useViewAllPosts = () : {
	get: SocialPostResponseInterface,
	set: (posts: SocialPostResponseInterface) => void,
	request: (payload: Partial<SocialPostRequestInterface>, payloadFill?: boolean) => void,
} => {
	const [currentPayload, setCurrentPayload] = useRecoilState(operationPostsRequest);
	const [posts, setPosts] = useRecoilState(operationPostsResponse);

	const httpGetPosts = async (payload: Partial<SocialPostRequestInterface>, payloadFill?: boolean) => {
		const newPayload: Partial<SocialPostRequestInterface> = payloadFill ? CloneAndAssign(currentPayload, payload) : payload;

		const resp = await APIGetPosts(newPayload as SocialPostRequestInterface);
		setPosts(resp);

		// mock, for fast, non-api unit tests and bugfixes
		// setPosts(await mockData(newPayload));

		setCurrentPayload(newPayload);
	};

	return {
		get: posts,
		set: setPosts,
		request: httpGetPosts,
	};
}

const mockData = async (payload: Partial<SocialPostRequestInterface>) => {
	const match = (MOCKED_POSTS as NodeJS.Dict<any>)
		.filter((e: any) => {
			// from: Date | string,
			// to: Date | string,
			// q: string,
			// source: string,
			// offset_start: number,
			// offset_end: number
			let matching = true;
			if (payload.q)
				matching = matching && (
					e.title.includes(payload.q) ||
					e.text.includes(payload.q) ||
					e.author.includes(payload.q)
				);
			if (payload.source)
				matching = matching && (SOCIAL_PLATFORMS.filter(ee => ee.name === e.src)[0].id === payload.source);
			if (payload.from && payload.to) {
				const f = new Date(payload.from).getTime();
				const t = new Date(payload.to).getTime();
				const p = new Date(e.post_date).getTime();
				matching = matching && p >= f && p <= t;
			}
			return matching;
		})
	;
	return {
		total: match.length,
		data: match.slice(
			payload.offset_start ? payload.offset_start : 0,
			payload.offset_end ? payload.offset_end + 1 : undefined
		)
	};
}