import React from "react";
import { connect } from "react-redux";
// import _ from "lodash";
import { addDays } from "date-fns";
import { Container as BootstrapContainer, Row, Col } from "react-bootstrap";
import { Tab, Container } from "semantic-ui-react";
import {
  exportComponentAsJPEG,
  // exportComponentAsPDF,
  // exportComponentAsPNG,
} from "react-component-export-image";

import {
  changePage,
  getStatData,
  getDetailData,
  getNonBankStatData,
  getNonBankDetailData,
  saveSearch,
  getPreset,
  getConfiguration,
  downloadCSV,
  nonBankDownloadCSV,
} from "../actions";

import OverallMentionCat from "./charts/OverallMentionCat";
import OverallBarChart from "./charts/OverallBarChart";
import OverallBankSentiment from "./charts/OverallBankSentiment";
import ControlPanel from "./ControlPanel";
import Wordcloud from "./charts/Wordcloud";
import Hashtag from "./charts/Hashtag";
import Heatmap from "./charts/Heatmap";
import SOV from "./charts/SOV";
import AggLineChart from "./charts/AggLineChart";
import BigAggLineChart from "./charts/BigAggLineChart";
import CustomerEngagement from "./charts/CustomerEngagement";
import BigSentimentLineChart from "./charts/BigSentimentLineChart";
import SentimentLineChart from "./charts/SentimentLineChart";
import DetailTable from "./charts/DetailTable";
import BigTable from "./charts/BigTable";
import ConfirmModal from "./ConfirmModal";
import BlockEmptySearchModal from "./BlockEmptySearchModal";
import DataNotFoundModal from "./DataNotFoundModal";
import CalendarModal from "./charts/CalendarModal";
import SaveQueryModal from "./SaveQueryModal";
import { generateLucene } from "../utils/Helper";

import {
  getWordcloudData,
  getHashTag,
  getRawAgg,
  getDataAggTimeSeries,
  getDataSentimentTimeSeries,
  getRawTableData,
  // getDataSentiment,
  sumAllMentionsEachBank,
  getEv,
  getOverallMentionCat,
} from "./dataProc";

const ROW_PER_PAGE = 50;
const START_PAGE_NUMBER = 1;

class Dashboard extends React.Component<any> {
  state: any = {
    startDate: addDays(new Date(), -7),
    // endDate: new Date(),
    // startDate: new Date('November 1, 2020 00:00:00'),
    endDate: new Date(),
    searchQuery: "",
    searchLucene: "",
    timeFrame: "1D",
    isShowCalendar: false,
    isShowBigAggLineChart: false,
    isShowBigSentimentLineChart: false,
    isShowBigTable: false,
    isShowConfirmModal: false,
    isShowBlockEmptySearchModal: false,
    isShowDataNotFoundModal: false,
    isShowSaveQueryModal: false,
    dispSources: [],
    dispTargets: [],
    dispSentiments: [],
    dispChannels: [],
    sourceOptions: [],
    bankOptions: [],
    sentimentOptions: [],
    channelOptions: [],
    bigTableFilter: {
      keyWord: "",
      date: "",
      source: "",
      related: "",
      sentiment: "",
    },
    detailTableFilter: {
      keyWord: "",
      date: "",
      source: "",
      related: "",
      sentiment: "",
    },
    rawWord: null,
    rawHashTag: null,
    rawAgg: null,
    dataAggTimeSeries: null,
    dataSentimentTimeSeries: null,
    rawTableData: null,
    // dataSentiment: null,
    dataMentionEachBank: null,
    currentTab: 0,
    preset: null,
    selectedPresetVal: "",
    colors: {},
    ev: {},
    overallMentionCat: null,
    detailDataCount: 0,
    bigTableActivePage: 1,
    detailTableActivePage: 1,
    isShowDetailTable: false,
    tableMode: "None",
    isBankQuery: true,
    nonBankQuery: [["Group1", "Query", "Query", "Valid", true]],
    selectedPresetIndex: 0,
  };
  [key: string]: any;

  componentDidMount() {
    this.props.showLoading();
    this.props.getConfiguration();
    this.props.getPreset();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      if (this.props.statData !== null) {
        if (this.props.statData.respondPayload.total !== 0) {
          if (
            this.state.sourceOptions.length === 1 &&
            this.state.bankOptions.length === 1 &&
            this.state.sentimentOptions.length === 1
          ) {
            const rawWordCloud = getWordcloudData(
              this.props.statData.respondPayload
            );
            const rawHashTag = getHashTag(this.props.statData.respondPayload);
            const rawAgg = getRawAgg(this.props.statData.respondPayload);
            const dataSentimentTimeSeries = getDataSentimentTimeSeries(
              this.props.statData.respondPayload
            );
            const dataMentionEachBank = sumAllMentionsEachBank(
              this.props.statData.respondPayload
            );
            const dataAggTimeSeries = getDataAggTimeSeries(
              this.props.statData.respondPayload
            );
            const overallMentionCat = getOverallMentionCat(
              this.props.statData.respondPayload
            );
            let rawTableData = null;
            let isShowBigTable = false;
            let isShowDetailTable = false;
            let detailDataCount = 0;
            if (this.props.detailData !== null) {
              if (this.state.tableMode === "Detail") {
                isShowDetailTable = true;
              } else if (this.state.tableMode === "Big") {
                isShowBigTable = true;
              }
              rawTableData = getRawTableData(
                this.props.detailData.respondPayload
              );
              detailDataCount = this.props.detailData.respondPayload.total;
            }
            let sourceOptions = this.props.statData.respondPayload.options.src;
            sourceOptions.push("ALL");
            let sourceOptionsDict = sourceOptions.map((val: any, idx: any) => {
              return { key: "src" + val, text: val.toUpperCase(), value: val };
            });

            let bankOptions = [];
            if (this.state.isBankQuery) {
              bankOptions = this.props.statData.respondPayload.options.bank;
            }
            bankOptions.push("ALL");
            let bankOptionsDict = bankOptions.map((val: any, idx: any) => {
              return { key: "bank" + val, text: val.toUpperCase(), value: val };
            });

            let sentimentOptions =
              this.props.statData.respondPayload.options.sentiment;
            sentimentOptions.push("ALL");
            let sentimentOptionsDict = sentimentOptions.map((val: any, idx: any) => {
              return {
                key: "sentiment" + val,
                text: val.toUpperCase(),
                value: val,
              };
            });

            let channelOptions =
              this.props.statData.respondPayload.options.channel.sort();

            channelOptions.push("ALL");
            let channelOptionsDict = channelOptions.map((val: any, idx: any) => {
              return {
                key: "channel" + val,
                text:
                  val !== "ALL"
                    ? val.toUpperCase().split("->").slice(1).join("->")
                    : val,
                value: val,
              };
            });
            const ev = getEv(this.props.statData.respondPayload);

            this.setState({
              sourceOptions: sourceOptionsDict,
              bankOptions: bankOptionsDict,
              sentimentOptions: sentimentOptionsDict,
              channelOptions: channelOptionsDict,
              dispSources: ["ALL"],
              dispTargets: ["ALL"],
              dispSentiments: ["ALL"],
              dispChannels: ["ALL"],
              rawWord: rawWordCloud,
              rawHashTag: rawHashTag,
              rawAgg: rawAgg,
              dataAggTimeSeries: dataAggTimeSeries,
              dataSentimentTimeSeries: dataSentimentTimeSeries,
              rawTableData: rawTableData,
              dataMentionEachBank: dataMentionEachBank,
              ev: ev,
              overallMentionCat: overallMentionCat,
              isShowBigTable: isShowBigTable,
              isShowDetailTable: isShowDetailTable,
              detailDataCount: detailDataCount,
            });
          } else {
            const rawWordCloud = getWordcloudData(
              this.props.statData.respondPayload
            );
            const rawHashTag = getHashTag(this.props.statData.respondPayload);
            const rawAgg = getRawAgg(this.props.statData.respondPayload);
            const dataSentimentTimeSeries = getDataSentimentTimeSeries(
              this.props.statData.respondPayload
            );
            const dataMentionEachBank = sumAllMentionsEachBank(
              this.props.statData.respondPayload
            );
            const dataAggTimeSeries = getDataAggTimeSeries(
              this.props.statData.respondPayload
            );
            const overallMentionCat = getOverallMentionCat(
              this.props.statData.respondPayload
            );
            let rawTableData = null;
            let isShowBigTable = false;
            let isShowDetailTable = false;
            let detailDataCount = 0;
            if (this.props.detailData !== null) {
              if (this.state.tableMode === "Detail") {
                isShowDetailTable = true;
              } else if (this.state.tableMode === "Big") {
                isShowBigTable = true;
              }
              rawTableData = getRawTableData(
                this.props.detailData.respondPayload
              );
              detailDataCount = this.props.detailData.respondPayload.total;
            }

            const ev = getEv(this.props.statData.respondPayload);
            let tmpColor: any = null;
            if (this.state.isBankQuery) {
              tmpColor = this.props.configuration.color;
            } else {
              tmpColor =
                this.state.preset[this.state.selectedPresetIndex].color;
            }
            let colors: any = {};
            if (rawAgg !== null) {
              rawAgg[0].data.yLabels.forEach((ele: any) => {
                colors[ele.toLowerCase()] =
                  tmpColor && tmpColor[ele]
                    ? tmpColor[ele.toLowerCase()]
                    : ele.toLowerCase() in this.state.colors
                      ? this.state.colors[ele.toLowerCase()]
                      : "#" +
                      ((Math.random() * 0xffffff) << 0)
                        .toString(16)
                        .padStart(6, "0");
              });
            }
            this.setState({
              rawWord: rawWordCloud,
              rawHashTag: rawHashTag,
              rawAgg: rawAgg,
              dataAggTimeSeries: dataAggTimeSeries,
              dataSentimentTimeSeries: dataSentimentTimeSeries,
              rawTableData: rawTableData,
              dataMentionEachBank: dataMentionEachBank,
              ev: ev,
              overallMentionCat: overallMentionCat,
              isShowBigTable: isShowBigTable,
              isShowDetailTable: isShowDetailTable,
              detailDataCount: detailDataCount,
              colors: colors,
            });
          }
          this.props.hideLoading();
        } else {
          this.setToggleShowDataNotFoundModal();
          this.props.hideLoading();
        }
      }
      if (
        this.props.preset !== null &&
        this.props.preset.length !== 0 &&
        this.props.configuration !== null &&
        this.state.searchQuery === ""
      ) {
        const allName = this.props.preset.map((ele: any) => ele.searchName);
        let idx = allName.indexOf(this.props.configuration.defaultPreset);
        idx = idx === -1 ? 0 : idx;

        this.setIsBankQueryFlag(this.props.preset[idx].isBankSearch);

        let sourceOptions = ["ALL"];
        let sourceOptionsDict = sourceOptions.map((val, idx) => {
          return { key: val, text: val.toUpperCase(), value: val };
        });
        let sentimentOptions = ["ALL"];
        let sentimentOptionsDict = sentimentOptions.map((val, idx) => {
          return { key: val, text: val.toUpperCase(), value: val };
        });
        let bankOptions = ["ALL"];
        let bankOptionsDict = bankOptions.map((val, idx) => {
          return { key: val, text: val.toUpperCase(), value: val };
        });
        let channelOptions = ["ALL"];
        let channelOptionsDict = channelOptions.map((val, idx) => {
          return { key: val, text: val.toUpperCase(), value: val };
        });
        this.deshboardRef = React.createRef();
        let searchText = this.state.searchQuery;
        let nonBankQuery = this.state.nonBankQuery;

        if (this.props.preset[idx].isBankSearch) {
          searchText = this.props.preset[idx].searchText;
        } else {
          const tmpSearchTexts = this.props.preset[idx].searchText.split("|");
          nonBankQuery = this.props.preset[idx].lucene
            .split("|")
            .slice(0, -1)
            .map((ele: any, i: any) => {
              let searchText = tmpSearchTexts[i].split(":")[0];
              let tmp = ele.split(":");
              let gName = tmp[1];
              let lucene = tmp[0];
              return [gName, searchText, lucene, "Valid", true];
            });
        }
        this.getStatData(
          this.props.preset[idx].isBankSearch,
          this.props.preset[idx].isBankSearch ? searchText : nonBankQuery,
          this.state.startDate,
          this.state.endDate,
          ROW_PER_PAGE,
          START_PAGE_NUMBER,
          this.state.timeFrame,
          [],
          [],
          [],
          this.state.dispSources,
          this.state.dispTargets,
          this.state.dispSentiments,
          this.props.loginUser.data.userId,
          this.state.dispChannels
        );
        this.setState({
          searchQuery: this.props.preset[idx].isBankSearch
            ? searchText
            : "get_all",
          searchLucene: this.props.preset[idx].isBankSearch
            ? generateLucene(searchText)
            : nonBankQuery[0][2],
          nonBankQuery: nonBankQuery,
          preset: this.props.preset,
          selectedPresetVal: this.props.preset[idx].isBankSearch
            ? searchText
            : this.props.preset[idx].searchText,
          sourceOptions: sourceOptionsDict,
          dispSources: [...sourceOptions.slice(0, -1)],
          bankOptions: bankOptionsDict,
          dispTargets: [...bankOptions.slice(0, -1)],
          sentimentOptions: sentimentOptionsDict,
          dispSentiments: [...sentimentOptions.slice(0, -1)],
          channelOptions: channelOptionsDict,
          dispChannels: [...channelOptions.slice(0, -1)],
          colors: this.props.preset[idx].isBankSearch
            ? this.props.configuration.color
            : this.props.preset[idx].color,
          selectedPresetIndex: idx,
        });
      }
    }
  }

  getStatData = (
    isBankQuery: any,
    query: any,
    startDate: any,
    endDate: any,
    rowPerPage: any,
    startPageNumber: any,
    timeFrame: any,
    keyword: any,
    include: any,
    exclude: any,
    dispSources: any,
    dispTargets: any,
    dispSentiments: any,
    userId: any,
    dispChannels: any
  ) => {
    if (isBankQuery) {
      const lucene = generateLucene(query);
      this.props.getStatData(
        startDate,
        endDate,
        rowPerPage,
        startPageNumber,
        timeFrame,
        keyword,
        include,
        exclude,
        lucene,
        dispSources,
        dispTargets,
        dispSentiments,
        userId,
        dispChannels
      );

      this.setState({
        searchQuery: query,
        searchLucene: lucene,
        selectedPresetVal: query,
      });
    } else {
      let queries: any[] = [];
      let query_gnames: any[] = [];
      query.forEach((ele: any, i: any) => {
        if (ele[4]) {
          queries.push(ele[2].replace('"', '"'));
          query_gnames.push(ele[0]);
        }
      });
      this.props.getNonBankStatData(
        startDate,
        endDate,
        rowPerPage,
        startPageNumber,
        timeFrame,
        keyword,
        include,
        exclude,
        queries,
        query_gnames,
        dispSources,
        dispTargets,
        dispSentiments,
        userId,
        dispChannels
      );
    }
  };

  getDetailData = (
    isBankQuery: any,
    query: any,
    startDate: any,
    endDate: any,
    rowPerPage: any,
    startPageNumber: any,
    timeFrame: any,
    keyword: any,
    include: any,
    exclude: any,
    dispSources: any,
    dispTargets: any,
    dispSentiments: any,
    userId: any,
    subQuery: any,
    sort: any,
    overideDatetime: any,
    channel: any
  ) => {
    if (isBankQuery) {
      const lucene = generateLucene(query);
      this.props.getDetailData(
        startDate,
        endDate,
        rowPerPage,
        startPageNumber,
        timeFrame,
        keyword,
        include,
        exclude,
        lucene,
        dispSources,
        dispTargets,
        dispSentiments,
        userId,
        subQuery,
        sort,
        overideDatetime,
        channel
      );
    } else {
      let queries: any[] = [];
      let query_gnames: any[] = [];
      query.forEach((ele: any, i: any) => {
        queries.push(ele[2].replace('"', '"'));
        query_gnames.push(ele[0]);
      });
      this.props.getNonBankDetailData(
        startDate,
        endDate,
        rowPerPage,
        startPageNumber,
        timeFrame,
        keyword,
        include,
        exclude,
        "",
        queries,
        query_gnames,
        dispSources,
        [],
        dispSentiments,
        userId,
        subQuery,
        sort,
        overideDatetime,
        channel
      );
    }
  };

  filterAggData = (data: any, dispSources: any, dispTargets: any, dispSentiments: any) => {
    let filteredData = data.filter(function (ele: any) {
      return dispSources.indexOf(ele.source) !== -1;
    });
    filteredData = filteredData.map((ele: any, idx: any) => {
      let keepYList: any = [];
      ele.data.yLabels.forEach((val: any, idx1: any) => {
        let keepIndex = dispTargets.indexOf(val) !== -1 ? idx1 : -1;
        if (keepIndex !== -1) keepYList.push(keepIndex);
      });
      let tmpDataList: any = [];
      keepYList.forEach((val: any, idx1: any) => {
        tmpDataList.push(ele.data.data[val]);
      });
      ele.data.yLabels = dispTargets;
      ele.data.data = tmpDataList;

      let keepXList: any = [];
      ele.data.xLabels.forEach((val: any, idx1: any) => {
        let keepIndex = dispSentiments.indexOf(val) !== -1 ? idx1 : -1;
        if (keepIndex !== -1) keepXList.push(keepIndex);
      });
      ele.data.xLabels = dispSentiments;
      ele.data.data.forEach((val2: any, idx: any) => {
        let tmpDataList: any = [];
        keepXList.forEach((val3: any, idx1: any) => {
          tmpDataList.push(ele.data.data[idx][val3]);
        });
        ele.data.data[idx] = tmpDataList;
      });

      return ele;
    });
    return filteredData;
  };

  filterSentimentData = (data: any, dispSources: any, dispTargets: any, dispSentiments: any) => {
    return data;
  };

  filterAggTimeSeries = (data: any, dispSources: any, dispTargets: any, dispSentiments: any) => {
    return data;
  };

  filterSentimentTimeSeries = (
    data: any,
    dispSources: any,
    dispTargets: any,
    dispSentiments: any
  ) => {
    let filteredData = data.filter(function (ele: any) {
      return dispSentiments.indexOf(ele.sentiment) !== -1;
    });
    return filteredData;
  };

  exeSearchQuery = (searchQuery: any, reset = false) => {
    if (searchQuery.length !== 0) {
      this.props.showLoading();
      this.getStatData(
        this.state.isBankQuery,
        searchQuery,
        this.state.startDate,
        this.state.endDate,
        ROW_PER_PAGE,
        START_PAGE_NUMBER,
        this.state.timeFrame,
        [],
        [],
        [],
        reset ? ["ALL"] : this.state.dispSources,
        reset ? ["ALL"] : this.state.dispTargets,
        reset ? ["ALL"] : this.state.dispSentiments,
        this.props.loginUser.data.userId,
        reset ? ["ALL"] : this.state.dispChannels
      );
      // if (reset) {
      //   this.setState({
      //     searchQuery: searchQuery,
      //     selectedPresetVal: searchQuery,
      //     searchLucene: generateLucene(searchQuery),
      //     dispSources: ["ALL"],
      //     dispTargets: ["ALL"],
      //     dispSentiments: ["ALL"],
      //   });
      // } else {
      //   console.log(searchQuery);
      //   this.setState({
      //     searchQuery: searchQuery,
      //     selectedPresetVal: searchQuery,
      //     searchLucene: generateLucene(searchQuery),
      //   });
      // }
    } else {
      this.setToggleShowBlockEmptySearchModal();
    }
  };

  setQuery = (searchQuery: any) => {
    this.setState({
      searchQuery: searchQuery,
      selectedPresetVal: searchQuery,
      searchLucene: generateLucene(searchQuery),
    });
  };

  setNonBankQuery = (searchQuery: any) => {
    this.setState({ nonBankQuery: searchQuery });
  };

  setSearchLucene = (lucene: any) => {
    this.setState({ searchLucene: lucene });
  };

  setTimeFrame = (timeFrame: any) => {
    this.setState({
      timeFrame: timeFrame,
    });
  };

  setDispSources = (dispSources: any) => {
    let newDispSource = dispSources;
    if (dispSources.length === 0) {
      newDispSource.push("ALL");
    }
    this.setState({ dispSources: newDispSource });
  };

  setDispTargets = (dispTargets: any) => {
    let newDispTarget = dispTargets;
    if (dispTargets.length === 0) {
      newDispTarget.push("ALL");
    }
    this.setState({ dispTargets: newDispTarget });
  };

  setDispSentiments = (dispSentiments: any) => {
    let newDispSentiments = dispSentiments;
    if (dispSentiments.length === 0) {
      newDispSentiments.push("ALL");
    }
    this.setState({ dispSentiments: newDispSentiments });
  };

  setDispChannels = (dispChannels: any) => {
    let newDispChannels = dispChannels;
    if (dispChannels.length === 0) {
      newDispChannels.push("ALL");
    }
    this.setState({ dispChannels: newDispChannels });
  };

  selectPreset = (value: any, isBankQuery: any) => {
    this.props.showLoading();
    this.getStatData(
      isBankQuery,
      value,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      START_PAGE_NUMBER,
      this.state.timeFrame,
      [],
      [],
      [],
      this.state.dispSources,
      this.state.dispTargets,
      this.state.dispSentiments,
      this.props.loginUser.data.userId,
      this.state.dispChannels
    );
  };

  setIsBankQueryFlag = (flag: any) => {
    this.setState({ isBankQuery: flag });
  };

  setSelectedPresetVal = (index: any, selectedPreset: any) => {
    this.setState({
      selectedPresetIndex: index,
      selectedPresetVal: selectedPreset,
    });
  };
  renderControlPanel = (
    sourceOptions: any,
    sentimentOptions: any,
    bankOptions: any,
    channelOptions: any
  ) => {
    if (this.state.preset !== null) {
      return (
        <ControlPanel
          setToggleCalendar={this.setToggleCalendar}
          exeSearchQuery={this.exeSearchQuery}
          exeSearchNonBankQuery={this.exeSearchNonBankQuery}
          setIsBankQueryFlag={this.setIsBankQueryFlag}
          isBankQuery={this.state.isBankQuery}
          setQuery={this.setQuery}
          searchQuery={this.state.searchQuery}
          setSearchLucene={this.setSearchLucene}
          searchLucene={this.state.searchLucene}
          setNonBankQuery={this.setNonBankQuery}
          nonBankQuery={this.state.nonBankQuery}
          selectedPresetVal={this.state.selectedPresetVal}
          setSelectedPresetVal={this.setSelectedPresetVal}
          selectPreset={this.selectPreset}
          preset={this.props.preset}
          setTimeFrame={this.setTimeFrame}
          sourceOptions={sourceOptions}
          sentimentOptions={sentimentOptions}
          bankOptions={bankOptions}
          channelOptions={channelOptions}
          setDispSources={this.setDispSources}
          setDispTargets={this.setDispTargets}
          setDispSentiments={this.setDispSentiments}
          setDispChannels={this.setDispChannels}
          setToggleShowConfirmModal={this.setToggleShowConfirmModal}
          setToggleShowSaveQueryModal={this.setToggleShowSaveQueryModal}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          dispSentiments={this.state.dispSentiments}
          dispSources={this.state.dispSources}
          dispTargets={this.state.dispTargets}
          dispChannels={this.state.dispChannels}
        ></ControlPanel>
      );
    }
    return null;
  };

  setToggleCalendar = () => {
    this.setState({ isShowCalendar: !this.state.isShowCalendar });
  };

  setToggleShowBigAggLineChart = () => {
    this.setState({ isShowBigAggLineChart: !this.state.isShowBigAggLineChart });
  };

  renderBigAggLineChart(dataAggTimeSeries: any, colors: any) {
    if (this.state.isShowBigAggLineChart) {
      return (
        <BigAggLineChart
          data={dataAggTimeSeries}
          setToggleShowBigAggLineChart={this.setToggleShowBigAggLineChart}
          colors={colors}
          exportAsJPEG={this.exportAsJPEG}
          setToggleShowBigTableWithFilter={this.setToggleShowBigTableWithFilter}
        ></BigAggLineChart>
      );
    }
    return null;
  }

  setToggleShowBigSentimentLineChart = () => {
    this.setState({
      isShowBigSentimentLineChart: !this.state.isShowBigSentimentLineChart,
    });
  };

  renderBigSentimentLineChart(dataSentimentTimeSeries: any, colors: any) {
    if (this.state.isShowBigSentimentLineChart) {
      return (
        <BigSentimentLineChart
          data={dataSentimentTimeSeries}
          setToggleShowBigSentimentLineChart={
            this.setToggleShowBigSentimentLineChart
          }
          colors={colors}
          exportAsJPEG={this.exportAsJPEG}
          setToggleShowBigTableWithFilter={this.setToggleShowBigTableWithFilter}
        ></BigSentimentLineChart>
      );
    }
    return null;
  }

  closeBigTable = () => {
    this.setState({
      tableData: null,
      isShowBigTable: false,
      bigTableFilter: null,
      bigTableActivePage: 1,
      tableMode: "None",
      isShowDetailTable: true,
      detailTableActivePage: this.state.bigTableActivePage,
    });
  };

  setToggleShowBigTable = () => {
    this.props.showLoading();
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchText : this.state.nonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      START_PAGE_NUMBER,
      this.state.timeFrame,
      [],
      [],
      [],
      this.state.dispSources,
      this.state.dispTargets,
      this.state.dispSentiments,
      this.props.loginUser.data.userId,
      null,
      null,
      null,
      this.state.dispChannels
    );

    this.setState({
      bigTableFilter: null,
      tableMode: "Big",
      activePage: 1,
    });
  };

  setToggleShowBigTableWithFilter = (bigTableFilter: any) => {
    this.props.showLoading();
    let tmpNonBankQuery = [];
    if (!this.state.isBankQuery) {
      if (
        bigTableFilter.related &&
        typeof bigTableFilter.related === "string"
      ) {
        tmpNonBankQuery = this.state.nonBankQuery.filter(
          (ele: any) => bigTableFilter.related.toLowerCase() === ele[0].toLowerCase()
        );
      } else {
        tmpNonBankQuery = this.state.nonBankQuery;
      }
    }
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : tmpNonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      START_PAGE_NUMBER,
      this.state.timeFrame,
      [],
      [],
      [],
      bigTableFilter.source
        ? typeof bigTableFilter.source === "string"
          ? [bigTableFilter.source]
          : bigTableFilter.source
        : this.state.dispSources,
      bigTableFilter.related
        ? typeof bigTableFilter.related === "string"
          ? [bigTableFilter.related]
          : bigTableFilter.related
        : this.state.dispTargets,
      bigTableFilter.sentiment
        ? typeof bigTableFilter.sentiment === "string"
          ? [bigTableFilter.sentiment]
          : bigTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
      bigTableFilter.sort ? bigTableFilter.sort : null,
      bigTableFilter.overide_datetime ? bigTableFilter.overide_datetime : null,
      bigTableFilter.category
        ? bigTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );

    this.setState({
      bigTableFilter: bigTableFilter,
      tableMode: "Big",
      activePage: 1,
    });
  };

  bigTableChangePage = (activePage: any) => {
    const bigTableFilter = this.state.bigTableFilter;
    this.props.showLoading();
    let tmpNonBankQuery = [];
    if (!this.state.isBankQuery) {
      if (
        bigTableFilter.related &&
        typeof bigTableFilter.related === "string"
      ) {
        tmpNonBankQuery = this.state.nonBankQuery.filter(
          (ele: any) => bigTableFilter.related.toLowerCase() === ele[0].toLowerCase()
        );
      } else {
        tmpNonBankQuery = this.state.nonBankQuery;
      }
    }
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : tmpNonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      activePage,
      this.state.timeFrame,
      [],
      [],
      [],
      bigTableFilter.source
        ? typeof bigTableFilter.source === "string"
          ? [bigTableFilter.source]
          : bigTableFilter.source
        : this.state.dispSources,
      bigTableFilter.related
        ? typeof bigTableFilter.related === "string"
          ? [bigTableFilter.related]
          : bigTableFilter.related
        : this.state.dispTargets,
      bigTableFilter.sentiment
        ? typeof bigTableFilter.sentiment === "string"
          ? [bigTableFilter.sentiment]
          : bigTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
      bigTableFilter.sort ? bigTableFilter.sort : null,
      bigTableFilter.overide_datetime ? bigTableFilter.overide_datetime : null,
      bigTableFilter.category
        ? bigTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );
    this.setState({
      bigTableFilter: bigTableFilter,
      isShowBigTable: true,
      bigTableActivePage: activePage,
    });
  };

  bigTableFilterFunction = (keyword: any) => {
    let bigTableFilter = this.state.bigTableFilter;
    bigTableFilter.sub_query = keyword;
    const activePage = 1;
    this.props.showLoading();
    let tmpNonBankQuery = [];
    if (!this.state.isBankQuery) {
      if (
        bigTableFilter.related &&
        typeof bigTableFilter.related === "string"
      ) {
        tmpNonBankQuery = this.state.nonBankQuery.filter(
          (ele: any) => bigTableFilter.related.toLowerCase() === ele[0].toLowerCase()
        );
      } else {
        tmpNonBankQuery = this.state.nonBankQuery;
      }
    }
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : tmpNonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      activePage,
      this.state.timeFrame,
      [],
      [],
      [],
      bigTableFilter.source
        ? typeof bigTableFilter.source === "string"
          ? [bigTableFilter.source]
          : bigTableFilter.source
        : this.state.dispSources,
      bigTableFilter.related
        ? typeof bigTableFilter.related === "string"
          ? [bigTableFilter.related]
          : bigTableFilter.related
        : this.state.dispTargets,
      bigTableFilter.sentiment
        ? typeof bigTableFilter.sentiment === "string"
          ? [bigTableFilter.sentiment]
          : bigTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
      bigTableFilter.sort ? bigTableFilter.sort : null,
      bigTableFilter.overide_datetime ? bigTableFilter.overide_datetime : null,
      bigTableFilter.category
        ? bigTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );

    this.setState({
      bigTableFilter: bigTableFilter,
      isShowBigTable: true,
      bigTableActivePage: activePage,
    });
  };

  bigTableSortFunction = (field: any, direction: any) => {
    const fieldDict: any = {
      related: "related",
      sentiment: "sentiment",
      source: "src",
      date: "post_date",
      text: "text",
    };
    let bigTableFilter = this.state.bigTableFilter;
    bigTableFilter.sort = {
      field: fieldDict[field],
      ascending: direction === "ascending" ? true : false,
    };
    const activePage = 1;
    this.props.showLoading();
    let tmpNonBankQuery = [];
    if (!this.state.isBankQuery) {
      if (
        bigTableFilter.related &&
        typeof bigTableFilter.related === "string"
      ) {
        tmpNonBankQuery = this.state.nonBankQuery.filter(
          (ele: any) => bigTableFilter.related.toLowerCase() === ele[0].toLowerCase()
        );
      } else {
        tmpNonBankQuery = this.state.nonBankQuery;
      }
    }
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : tmpNonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      activePage,
      this.state.timeFrame,
      [],
      [],
      [],
      bigTableFilter.source
        ? typeof bigTableFilter.source === "string"
          ? [bigTableFilter.source]
          : bigTableFilter.source
        : this.state.dispSources,
      bigTableFilter.related
        ? typeof bigTableFilter.related === "string"
          ? [bigTableFilter.related]
          : bigTableFilter.related
        : this.state.dispTargets,
      bigTableFilter.sentiment
        ? typeof bigTableFilter.sentiment === "string"
          ? [bigTableFilter.sentiment]
          : bigTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
      bigTableFilter.sort ? bigTableFilter.sort : null,
      bigTableFilter.overide_datetime ? bigTableFilter.overide_datetime : null,
      bigTableFilter.category
        ? bigTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );
    this.setState({
      bigTableFilter: bigTableFilter,
      isShowBigTable: true,
      bigTableActivePage: activePage,
    });
  };

  detailTableSortFunction = (field: any, direction: any) => {
    const fieldDict: any = {
      related: "related",
      sentiment: "sentiment",
      source: "src",
      date: "post_date",
      text: "text",
    };
    let detailTableFilter = this.state.detailTableFilter;
    detailTableFilter.sort = {
      field: fieldDict[field],
      ascending: direction === "ascending" ? true : false,
    };
    const activePage = 1;
    this.props.showLoading();
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : this.state.nonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      activePage,
      this.state.timeFrame,
      [],
      [],
      [],
      detailTableFilter.source
        ? typeof detailTableFilter.source === "string"
          ? [detailTableFilter.source]
          : detailTableFilter.source
        : this.state.dispSources,
      detailTableFilter.related
        ? typeof detailTableFilter.related === "string"
          ? [detailTableFilter.related]
          : detailTableFilter.related
        : this.state.dispTargets,
      detailTableFilter.sentiment
        ? typeof detailTableFilter.sentiment === "string"
          ? [detailTableFilter.sentiment]
          : detailTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      detailTableFilter.sub_query ? detailTableFilter.sub_query : null,
      detailTableFilter.sort ? detailTableFilter.sort : null,
      detailTableFilter.overide_datetime
        ? detailTableFilter.overide_datetime
        : null,
      detailTableFilter.category
        ? detailTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );
    this.setState({
      detailTableFilter: detailTableFilter,
      detailTableActivePage: activePage,
    });
  };

  detailTableChangePage = (activePage: any) => {
    const detailTableFilter = this.state.detailTableFilter;
    this.props.showLoading();
    this.getDetailData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : this.state.nonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      activePage,
      this.state.timeFrame,
      [],
      [],
      [],
      detailTableFilter.source
        ? typeof detailTableFilter.source === "string"
          ? [detailTableFilter.source]
          : detailTableFilter.source
        : this.state.dispSources,
      detailTableFilter.related
        ? typeof detailTableFilter.related === "string"
          ? [detailTableFilter.related]
          : detailTableFilter.related
        : this.state.dispTargets,
      detailTableFilter.sentiment
        ? typeof detailTableFilter.sentiment === "string"
          ? [detailTableFilter.sentiment]
          : detailTableFilter.sentiment
        : this.state.dispSentiments,
      this.props.loginUser.data.userId,
      detailTableFilter.sub_query ? detailTableFilter.sub_query : null,
      detailTableFilter.sort ? detailTableFilter.sort : null,
      detailTableFilter.overide_datetime
        ? detailTableFilter.overide_datetime
        : null,
      detailTableFilter.category
        ? detailTableFilter.category.concat(
          this.state.dispChannels.indexOf("ALL") !== -1
            ? []
            : this.state.dispChannels
        )
        : this.state.dispChannels
    );

    this.setState({
      detailTableActivePage: activePage,
      detailTableFilter: detailTableFilter,
      isShowDetailTable: true,
      tableMode: "Detail",
    });
  };

  setBigTableFilter = (bigTableFilter: any) => {
    this.setState({ bigTableFilter: bigTableFilter });
  };

  renderBigTable(dataTable: any, bigTableFilter: any, colors: any) {
    if (this.state.isShowBigTable) {
      return (
        <BigTable
          data={dataTable}
          closeBigTable={this.closeBigTable}
          colors={colors}
          filter={bigTableFilter}
          rowPerPage={ROW_PER_PAGE}
          dataCount={this.state.detailDataCount}
          bigTableChangePage={this.bigTableChangePage}
          filterFunction={this.bigTableFilterFunction}
          sortFunction={this.bigTableSortFunction}
          activePage={this.state.bigTableActivePage}
          downloadCSV={this.bigTableDownloadCSV}
          csvDownloadURL={this.props.csvDownloadURL}
        ></BigTable>
      );
    }
    return null;
  }

  setDateRange = (dateRange: any) => {
    this.setState({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  };

  setToggleShowConfirmModal = () => {
    this.setState({
      isShowConfirmModal: !this.state.isShowConfirmModal,
    });
  };

  setToggleShowDataNotFoundModal = () => {
    this.setState({
      isShowDataNotFoundModal: !this.state.isShowDataNotFoundModal,
    });
  };

  setToggleShowSaveQueryModal = () => {
    this.setState({
      isShowSaveQueryModal: !this.state.isShowSaveQueryModal,
    });
  };

  saveDashBoardAsJPEG = () => {
    exportComponentAsJPEG(this.deshboardRef,
      {
        fileName: "DashBoard.jpeg",
        html2CanvasOptions: {
          backgroundColor: "#FFFFFF"
        }
      });
    this.setToggleShowConfirmModal();
  };

  saveSearchQuery = (name: any) => {
    this.props.showLoading();
    if (this.state.isBankQuery) {
      this.props.saveSearch(
        name,
        this.state.searchQuery,
        this.state.searchLucene,
        this.state.isBankQuery,
        null
      );
    } else {
      let searchLucene = "";
      let searchQuery = "";
      this.state.nonBankQuery.forEach((ele: any) => {
        searchLucene += `${ele[2]}:${ele[0]}|`;
        searchQuery += `${ele[1]}:${ele[0]}|`;
      });
      let colors: any = {};
      this.state.nonBankQuery.forEach((element: any) => {
        colors[element[0].toLowerCase()] =
          "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
      });
      this.props.saveSearch(
        name,
        searchQuery,
        searchLucene,
        this.state.isBankQuery,
        colors
      );
    }

    this.getStatData(
      this.state.isBankQuery,
      this.state.isBankQuery ? this.state.searchQuery : this.state.nonBankQuery,
      this.state.startDate,
      this.state.endDate,
      ROW_PER_PAGE,
      START_PAGE_NUMBER,
      this.state.timeFrame,
      [],
      [],
      [],
      this.state.dispSources,
      this.state.dispTargets,
      this.state.dispSentiments,
      this.props.loginUser.data.userId,
      this.state.dispChannels
    );
  };

  renderConfirmModal = () => {
    if (this.state.isShowConfirmModal)
      return (
        <ConfirmModal
          size={"lg"}
          header={"Export"}
          topic={""}
          description={
            "Do you really want to export the dashboard as JPEG file?"
          }
          button1={"Export"}
          button2={"Cancel"}
          button1Style={"primary"}
          button2Style={"secondary"}
          callBack1={this.saveDashBoardAsJPEG}
          callBack2={this.setToggleShowConfirmModal}
        ></ConfirmModal>
      );
    return null;
  };

  renderBlockEmptySearchModal = () => {
    if (this.state.isShowBlockEmptySearchModal)
      return (
        <BlockEmptySearchModal
          size={"sm"}
          header={"Warning"}
          topic={""}
          description={
            "Empty search query is not allowed. Please provide at lease one query.."
          }
          button1={"Search"}
          button2={"Cancel"}
          button1Style={"primary"}
          button2Style={"secondary"}
          callBack1={this.setSearchQuery}
          callBack2={this.setToggleShowBlockEmptySearchModal}
        ></BlockEmptySearchModal>
      );
    return null;
  };

  renderSaveQueryModal = () => {
    if (this.state.isShowSaveQueryModal)
      return (
        <SaveQueryModal
          size={"sm"}
          header={"Save Search Query"}
          topic={""}
          description={`Please enter search query name`}
          button1={"Save"}
          button2={"Cancel"}
          button1Style={"primary"}
          button2Style={"secondary"}
          callBack1={this.saveSearchQuery}
          callBack2={this.setToggleShowSaveQueryModal}
          preset={this.state.preset}
          searchQuery={this.state.searchQuery}
        ></SaveQueryModal>
      );
    return null;
  };

  renderDataNotFoundModal = () => {
    if (this.state.isShowDataNotFoundModal) {
      return (
        <Container as={BootstrapContainer}>
          {this.renderCalendar()}
          <DataNotFoundModal
            size={"sm"}
            header={"Warning"}
            topic={""}
            description={
              "Related content not found regarding this search query or date period, please provide the new search criteria"
            }
            preset={this.state.preset}
            button1={"Search"}
            button1Style={"primary"}
            prevSearchKeyword={this.state.searchQuery}
            searchQuery={this.state.searchQuery}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            callBack1={this.setSearchQuery}
            callBack2={this.setToggleCalendar}
            setQuery={this.setQuery}
            setToggleShowDataNotFoundModal={this.setToggleShowDataNotFoundModal}
          ></DataNotFoundModal>
        </Container>
      );
    }
    return null;
  };

  exportAsJPEG = (ref: any, filename: any, bgColor: any) => {
    exportComponentAsJPEG(ref, {
      fileName: filename,
      html2CanvasOptions: {
        backgroundColor: bgColor
      }
    });
  };

  renderCalendar = () => {
    if (this.state.isShowCalendar) {
      return (
        <CalendarModal
          setDateRange={this.setDateRange}
          setToggleCalendar={this.setToggleCalendar}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        ></CalendarModal>
      );
    }
    return null;
  };

  renderFirstTab = (
    rawWord: any,
    rawHashTag: any,
    dataMentionEachBank: any,
    dataAgg: any,
    colors: any
  ) => {
    if (this.state.currentTab === 0) {
      return (
        <Container as={BootstrapContainer} /*text*/>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ alignContent: "center" }}>
              <OverallBarChart
                data={dataMentionEachBank}
                colors={colors}
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                exportAsJPEG={this.exportAsJPEG}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></OverallBarChart>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col lg={12} md={12} xs={12} style={{ alignContent: "center" }}>
              <OverallBankSentiment
                data={dataAgg}
                colors={colors}
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                exportAsJPEG={this.exportAsJPEG}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></OverallBankSentiment>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col lg={6} md={6} xs={12}>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <SOV
                    data={dataMentionEachBank}
                    colors={colors}
                    exportAsJPEG={this.exportAsJPEG}
                    setToggleShowBigTableWithFilter={
                      this.setToggleShowBigTableWithFilter
                    }
                  ></SOV>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col lg={12} md={12} xs={12}>
                  <Wordcloud
                    data={rawWord}
                    exportAsJPEG={this.exportAsJPEG}
                    setToggleShowBigTableWithFilter={
                      this.setToggleShowBigTableWithFilter
                    }
                  ></Wordcloud>
                </Col>
              </Row>
              {rawHashTag.length !== 0 ? (
                <Row style={{ marginTop: "15px" }}>
                  <Col lg={12} md={12} xs={12}>
                    <Hashtag
                      data={rawHashTag}
                      exportAsJPEG={this.exportAsJPEG}
                      setToggleShowBigTableWithFilter={
                        this.setToggleShowBigTableWithFilter
                      }
                    ></Hashtag>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Heatmap
                data={dataAgg}
                colors={colors}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
                exportAsJPEG={this.exportAsJPEG}
              ></Heatmap>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderSecondTab = (overallMentionCat: any, colors: any) => {
    if (this.state.currentTab === 1) {
      return (
        <Container as={BootstrapContainer} /*text*/>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ alignContent: "center" }}>
              <OverallMentionCat
                data={overallMentionCat}
                colors={colors}
                exportAsJPEG={this.exportAsJPEG}
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></OverallMentionCat>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };
  dataSentimentTimeSeries: any;
  renderThirdTab = (
    // dataSentiment,
    dataAggTimeSeries: any,
    dataSentimentTimeSeries: any,
    colors: any
  ) => {
    if (this.state.currentTab === 2) {
      return (
        <Container as={BootstrapContainer} /*text*/>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ marginTop: "10px" }}>
              <AggLineChart
                data={dataAggTimeSeries}
                setToggleShowBigAggLineChart={this.setToggleShowBigAggLineChart}
                colors={colors}
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></AggLineChart>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ marginTop: "10px" }}>
              <Doughnut
                data={dataSentiment}
                colors={colors}
                exportAsJPEG={this.exportAsJPEG}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></Doughnut>
            </Col>
          </Row> */}
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ marginTop: "10px" }}>
              <SentimentLineChart
                data={dataSentimentTimeSeries}
                setToggleShowBigSentimentLineChart={
                  this.setToggleShowBigSentimentLineChart
                }
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                colors={colors}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
              ></SentimentLineChart>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderForthTab = (tableData: any, colors: any) => {
    if (this.state.currentTab === 3 && this.state.isShowDetailTable) {
      return (
        <Container as={BootstrapContainer} /*text*/>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ marginTop: "10px" }}>
              <DetailTable
                data={tableData}
                setToggleShowBigTableWithFilter={
                  this.setToggleShowBigTableWithFilter
                }
                colors={colors}
                rowPerPage={ROW_PER_PAGE}
                dataCount={this.state.detailDataCount}
                detailTableChangePage={this.detailTableChangePage}
                activePage={this.state.detailTableActivePage}
                dispSources={this.state.dispSources}
                dispTargets={this.state.dispTargets}
                dispSentiments={this.state.dispSentiments}
                sortFunction={this.detailTableSortFunction}
              ></DetailTable>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderFifthTab = (tableData: any, colors: any) => {
    if (this.state.currentTab === 4) {
      return (
        <Container as={BootstrapContainer} /*text*/>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12} md={12} xs={12} style={{ marginTop: "10px" }}>
              <CustomerEngagement data={this.state.ev}></CustomerEngagement>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderCharts = (
    rawWord: any,
    rawHashTag: any,
    dataAgg: any,
    // dataSentiment,
    dataMentionEachBank: any,
    dataAggTimeSeries: any,
    dataSentimentTimeSeries: any,
    overallMentionCat: any,
    tableData: any,
    colors: any
  ) => {
    // if (this.props.statData !== null) {
    if (
      this.state.rawWord !== null &&
      this.state.rawHashTag !== null &&
      this.state.rawAgg !== null &&
      this.state.dataAggTimeSeries !== null &&
      this.state.dataSentimentTimeSeries !== null &&
      this.state.overallMentionCat !== null &&
      this.state.dataSentiment !== null &&
      this.state.preset !== null
    ) {
      if (this.props.statData.respondPayload.total !== 0) {
        const panes = [
          {
            menuItem: { key: "tab1", icon: "comments", content: "Overall" },
            pane: (
              <Tab.Pane key="tab1">
                {this.renderFirstTab(
                  rawWord,
                  rawHashTag,
                  dataMentionEachBank,
                  dataAgg,
                  colors
                )}
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              key: "tab2",
              icon: "object group",
              content: "Topic Classification",
            },
            pane: (
              <Tab.Pane key="tab2">
                {this.renderSecondTab(overallMentionCat, colors)}
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              key: "tab3",
              icon: "chart area",
              content: "Time Series",
            },
            pane: (
              <Tab.Pane key="tab3">
                {this.renderThirdTab(
                  // dataSentiment,
                  dataAggTimeSeries,
                  dataSentimentTimeSeries,
                  colors
                )}
              </Tab.Pane>
            ),
          },
          {
            menuItem: { key: "tab4", icon: "th list", content: "Detail Table" },
            pane: (
              <Tab.Pane key="tab4" style={{ marginBottom: "10px" }}>
                {this.renderForthTab(tableData, colors)}
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              key: "tab5",
              icon: "empire",
              content: "Customer Engagement",
            },
            pane: (
              <Tab.Pane key="tab5" style={{ marginBottom: "10px" }}>
                {this.renderFifthTab(tableData, colors)}
              </Tab.Pane>
            ),
          },
        ];
        return (
          <div>
            {this.renderCalendar()}
            {this.renderBigAggLineChart(dataAggTimeSeries, colors)}
            {this.renderBigSentimentLineChart(dataSentimentTimeSeries, colors)}
            {this.renderBigTable(tableData, this.state.bigTableFilter, colors)}
            <Tab
              menu={{
                color: "grey",
                inverted: true,
                attached: true,
              }}
              panes={panes}
              renderActiveOnly={false}
              onTabChange={(event: any, data: any) => {
                let isShowDetailTable = false;
                if (data.activeIndex === 3) {
                  this.props.showLoading();
                  isShowDetailTable = true;

                  this.getDetailData(
                    this.state.isBankQuery,
                    this.state.isBankQuery
                      ? this.state.searchQuery
                      : this.state.nonBankQuery,
                    this.state.startDate,
                    this.state.endDate,
                    ROW_PER_PAGE,
                    START_PAGE_NUMBER,
                    this.state.timeFrame,
                    [],
                    [],
                    [],
                    this.state.dispSources,
                    this.state.dispTargets,
                    this.state.dispSentiments,
                    this.props.loginUser.data.userId,
                    null,
                    null,
                    null,
                    this.state.dispChannels
                  );
                  this.setState({
                    currentTab: data.activeIndex,
                    tableMode: "Detail",
                    detailTableActivePage: 1,
                  });
                } else {
                  this.setState({
                    currentTab: data.activeIndex,
                    tableMode: "None",
                    detailTableActivePage: 1,
                  });
                }
              }}
            />
          </div>
        );
      } else {
        return null;
      }
    }
    return null;
  };

  bigTableDownloadCSV = () => {
    let bigTableFilter = this.state.bigTableFilter;
    const activePage = 1;
    this.props.showLoading();
    if (this.state.isBankQuery) {
      this.props.downloadCSV(
        this.state.startDate,
        this.state.endDate,
        ROW_PER_PAGE,
        activePage,
        this.state.timeFrame,
        [],
        [],
        [],
        this.state.searchLucene,
        bigTableFilter.source
          ? typeof bigTableFilter.source === "string"
            ? [bigTableFilter.source]
            : bigTableFilter.source
          : this.state.dispSources,
        bigTableFilter.related
          ? typeof bigTableFilter.related === "string"
            ? [bigTableFilter.related]
            : bigTableFilter.related
          : this.state.dispTargets,
        bigTableFilter.sentiment
          ? typeof bigTableFilter.sentiment === "string"
            ? [bigTableFilter.sentiment]
            : bigTableFilter.sentiment
          : this.state.dispSentiments,
        this.props.loginUser.data.userId,
        bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
        bigTableFilter.sort ? bigTableFilter.sort : null,
        bigTableFilter.overide_datetime
          ? bigTableFilter.overide_datetime
          : null,
        bigTableFilter.category
          ? bigTableFilter.category.concat(
            this.state.dispChannels.indexOf("ALL") !== -1
              ? []
              : this.state.dispChannels
          )
          : this.state.dispChannels
      );
    } else {
      let tmpNonBankQuery = [];
      if (!this.state.isBankQuery) {
        if (
          bigTableFilter.related &&
          typeof bigTableFilter.related === "string"
        ) {
          tmpNonBankQuery = this.state.nonBankQuery.filter(
            (ele: any) =>
              bigTableFilter.related.toLowerCase() === ele[0].toLowerCase()
          );
        } else {
          tmpNonBankQuery = this.state.nonBankQuery;
        }
      }

      let queries: any[] = [];
      let query_gnames: any[] = [];
      tmpNonBankQuery.forEach((ele: any, i: any) => {
        if (ele[4]) {
          queries.push(ele[2].replace('"', '"'));
          query_gnames.push(ele[0]);
        }
      });
      // console.log(queries);
      // console.log(query_gnames);
      this.props.nonBankDownloadCSV(
        this.state.startDate,
        this.state.endDate,
        ROW_PER_PAGE,
        activePage,
        this.state.timeFrame,
        [],
        [],
        [],
        queries,
        query_gnames,
        bigTableFilter.source
          ? typeof bigTableFilter.source === "string"
            ? [bigTableFilter.source]
            : bigTableFilter.source
          : this.state.dispSources,
        [],
        bigTableFilter.sentiment
          ? typeof bigTableFilter.sentiment === "string"
            ? [bigTableFilter.sentiment]
            : bigTableFilter.sentiment
          : this.state.dispSentiments,
        this.props.loginUser.data.userId,
        bigTableFilter.sub_query ? bigTableFilter.sub_query : null,
        bigTableFilter.sort ? bigTableFilter.sort : null,
        bigTableFilter.overide_datetime
          ? bigTableFilter.overide_datetime
          : null,
        bigTableFilter.category
          ? bigTableFilter.category.concat(
            this.state.dispChannels.indexOf("ALL") !== -1
              ? []
              : this.state.dispChannels
          )
          : this.state.dispChannels
      );
    }
  };

  render() {
    const colors = this.state.colors;
    const rawHashTag = this.state.rawHashTag;
    const rawWord = this.state.rawWord;
    const dataAgg = this.state.rawAgg;
    const dataAggTimeSeries = this.state.dataAggTimeSeries;

    const dataMentionEachBank = this.state.dataMentionEachBank;
    const dataSentimentTimeSeries = this.state.dataSentimentTimeSeries;
    const overallMentionCat = this.state.overallMentionCat;
    const tableData = this.state.rawTableData;
    return (
      <Container as={BootstrapContainer} ref={this.deshboardRef} style={{ marginTop: "80px" }}>
        {this.renderControlPanel(
          this.state.sourceOptions,
          this.state.sentimentOptions,
          this.state.bankOptions,
          this.state.channelOptions
        )}
        {this.renderConfirmModal()}
        {this.renderBlockEmptySearchModal()}
        {this.renderSaveQueryModal()}
        {this.renderDataNotFoundModal()}
        {this.renderCharts(
          rawWord,
          rawHashTag,
          dataAgg,
          // dataSentiment,
          dataMentionEachBank,
          dataAggTimeSeries,
          dataSentimentTimeSeries,
          overallMentionCat,
          tableData,
          colors
        )}
      </Container>
    );
  }
}

const mapStateToProps = (states: any) => {
  return {
    currentPage: states.page.currentPage,
    loginUser: states.loginUser,
    statData: states.statData,
    detailData: states.detailData,
    preset: states.preset,
    configuration: states.configuration,
    csvDownloadURL: states.csvDownloadURL,
  };
};

export default connect(mapStateToProps, {
  changePage,
  getStatData,
  getDetailData,
  getNonBankStatData,
  getNonBankDetailData,
  saveSearch,
  getPreset,
  getConfiguration,
  downloadCSV,
  nonBankDownloadCSV,
})(Dashboard);
