import { useState } from "react";
import { DateRangeInterface } from "../models/date-range";

export const useDateRange = (initialVal?: DateRangeInterface) => {
	const [dateRange, setDateRange] = useState<DateRangeInterface>(
		initialVal ? initialVal : {
			startDate: new Date(),
			endDate: new Date()
		}
	);

	return {
		get: dateRange,
		set: (range: DateRangeInterface) => setDateRange(range)
	};
}