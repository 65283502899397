import React from "react";
// import { connect } from "react-redux";
import { Card, Label, Icon, Header } from "semantic-ui-react";
import { Container, Row, Col } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { capitalizeText } from "../../utils/Helper";

class SentimentLineChart extends React.Component<any> {
  state = { isShowSuggest: false };

  renderClickToExpandSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="small" pointing="right">
          Click to expand the chart
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderContent = (data: any) => {
    return Object.keys(data).map((key, idx) => {
      return (
        <Row key={`sntTime_${idx}`}>
          <Header as="h4">{capitalizeText(key)}</Header>
          <Col lg={12} md={12} xs={12}>
            <LineChart
              width={1000}
              height={150}
              data={data[key]}
              margin={{
                top: 5,
                right: 40,
                left: 0,
                bottom: 5,
              }}
              style={{ cursor: "pointer" }}
              onClick={(param) => {
                const bigTableFilter = {
                  overide_datetime: param.activeLabel.split("+")[0],
                  source: this.props.dispSources,
                  related: this.props.dispTargets,
                  sentiment: key,
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="Tick"
                minTickGap={10}
                interval={"preserveStartEnd"}
                hide={true}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(this.props.colors).map((key) => {
                return (
                  <Line
                    key={`aggLine_${idx}_${key}`}
                    type="monotone"
                    dataKey={key}
                    stroke={this.props.colors[key]}
                  />
                );
              })}
            </LineChart>
          </Col>
        </Row>
      );
    });
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Sentimental Mention Count
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToExpandSuggestion()}
              <Icon
                name="expand"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={this.props.setToggleShowBigSentimentLineChart}
              />
            </div>
          </Card.Header>
          <Card.Meta>
            Mention count during the period of time per sentiment
          </Card.Meta>
        </Card.Content>
        <Card.Content>
          <Container>{this.renderContent(this.props.data)}</Container>
        </Card.Content>
      </Card>
    );
  }
}

export default SentimentLineChart;
