import React from "react";
import { TagCloud } from "react-tagcloud";
import { Card, Label, Icon, Container } from "semantic-ui-react";
import "d3-transition";

class Wordcloud extends React.Component<any> {
  state = { isShowSuggest: false };
  [key: string]: any;

  componentDidMount() {
    this.ref = React.createRef();
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  colorOptions = {
    luminosity: "dark",
    hue: "blue",
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Active Term
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right          const bigTableFilter = {
                  sub_query: word.text,
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(
                    this.ref,
                    "WordCloud.jpeg",
                    "#FFFFFF"
                  );
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>
            The most active terms at the particular period of time
          </Card.Meta>
        </Card.Content>
        <Card.Content>
          <div ref={this.ref} style={{ width: "100%", height: "100%" }}>
            <Container
              as={TagCloud}
              tags={this.props.data}
              minSize={10}
              maxSize={30}
              colorOptions={this.colorOptions}
              className="simple-cloud"
              onClick={(param: any) => {
                const bigTableFilter = {
                  sub_query: param.value,
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default Wordcloud;
