import React from "react";
import { connect } from "react-redux";
import { Collapse, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { changePage, logout } from "../actions";
import { Button, Icon } from "semantic-ui-react";
// import ConfirmModal from "./ConfirmModal";

class Header extends React.Component<any> {
  state = { open: false };

  componentDidMount() {
    this.setState({ open: true });
  }

  renderMenu = () => {
    if (this.props.loginUser.data) {
      return (
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              onClick={() => {
                this.props.changePage("Home");
              }}
              href="#Home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                this.props.changePage("Dashboard");
              }}
              href="#Dashboard"
            >
              Dashboard
            </Nav.Link>
            {/* <Nav.Link
              onClick={() => {
                this.props.changePage("Operation");
              }}
              href="#Operation"
            >
              Operation
            </Nav.Link> */}
            <NavDropdown title="Control" id="collasible-nav-dropdown">
              <NavDropdown.Item
                onClick={() => {
                  this.props.changePage("Manage");
                }}
                href="#Manage"
              >
                Manage Dashboard
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Navbar.Text>
              Signed in as:
              <a
                style={{ marginLeft: "5px" }}
                className={"userEmail"}
                href="#login"
              >
                {this.props.loginUser.data.emails[0].value}
              </a>
            </Navbar.Text>
          </Nav>
          <Nav>
            <Nav.Link href="#logout">
              <Button
                fluid
                color="google plus"
                onClick={() => {
                  this.props.logout();
                }}
              >
                <Icon name="sign out alternate" /> Sign out
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <Collapse in={this.state.open}>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            fixed="top"
          >
            <Navbar.Brand
              href="#home"
              onClick={() => {
                this.props.changePage("Home");
              }}
            >
              Social Monitoring Tools
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {this.renderMenu()}
          </Navbar>
        </Collapse>
        {/* {this.renderConfirmLogout()} */}
      </div>
    );
  }
}
const mapStateToProps = (states: any) => {
  return {
    loginUser: states.loginUser,
  };
};
export default connect(mapStateToProps, { changePage, logout })(Header);
