import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Message, Icon } from "semantic-ui-react";

class SaveQueryModal extends React.Component<any> {
  state: any = {
    queryName: "",
    isShowWarning: false,
    allPresetName: [],
    dupIndex: 0,
  };

  componentDidMount() {
    const allPresetName = this.props.preset.map((ele: any) => {
      return ele.searchName;
    });
    this.setState({ allPresetName });
  }

  renderWarning = () => {
    if (this.state.isShowWarning) {
      return (
        <Message style={{ marginTop: "10px" }} warning attached="bottom">
          <Icon name="warning" />
          You've entered the name that already existed, the search query (
          {this.props.preset[this.state.dupIndex].searchText}) of "
          {this.state.queryName}" will be overrided by the new search query (
          {this.props.searchQuery}).
        </Message>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{this.props.topic}</h4>
          <p>{this.props.description}</p>
          <Input
            fluid
            focus
            placeholder="Query name"
            onChange={(event) => {
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });

              let text = event.target.value;
              text = text.toLowerCase();
              this.setState({
                queryName: text,
                isShowWarning:
                  this.state.allPresetName.indexOf(text) !== -1 ? true : false,
                dupIndex: this.state.allPresetName.indexOf(text),
              });
            }}
            value={this.state.queryName}
          />
          {this.renderWarning()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.state.queryName.length === 0}
            variant={this.props.button1Style}
            onClick={() => {
              this.props.callBack1(this.state.queryName);
              this.props.callBack2();
            }}
          >
            {this.props.button1}
          </Button>
          <Button
            variant={this.props.button2Style}
            onClick={this.props.callBack2}
          >
            {this.props.button2}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SaveQueryModal;
