import React from "react";
// import { connect } from "react-redux";
import { Card, Label, Icon } from "semantic-ui-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

class AggLineChart extends React.Component<any> {
  state = { isShowSuggest: false };

  renderClickToExpandSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="small" pointing="right">
          Click to expand the chart
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Total Mention Count
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToExpandSuggestion()}
              <Icon
                name="expand"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={this.props.setToggleShowBigAggLineChart}
              />
            </div>
          </Card.Header>
          <Card.Meta>Total mention count each bank</Card.Meta>
        </Card.Content>
        <Card.Content>
          <div style={{ width: "100%", height: "100%", marginLeft: "15px" }}>
            <LineChart
              width={1000}
              height={230}
              data={this.props.data}
              margin={{
                top: 5,
                right: 40,
                left: 0,
                bottom: 50,
              }}
              style={{ cursor: "pointer" }}
              onClick={(param) => {
                const bigTableFilter = {
                  overide_datetime: param.activeLabel.split("+")[0],
                  source: this.props.dispSources,
                  related: this.props.dispTargets,
                  sentiment: this.props.dispSentiments,
                };
                this.props.setToggleShowBigTableWithFilter(bigTableFilter);
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="Tick"
                minTickGap={10}
                interval={"preserveStartEnd"}
                hide={true}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(this.props.colors).map((key) => {
                return (
                  <Line
                    key={`aggLine_${key}`}
                    type="monotone"
                    dataKey={key}
                    stroke={this.props.colors[key]}
                  />
                );
              })}
              {/*
              1st option https://codesandbox.io/s/1o6x11lvn7?file=/index.js 
              2nd option https://react-charts.js.org/examples/brushing */}
            </LineChart>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default AggLineChart;
