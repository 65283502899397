import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Segment } from "semantic-ui-react";
import { generateLucene } from "../utils/Helper";

class AddNewPresetModal extends React.Component<any> {
  state = { searchQuery: "", searchName: "", searchLucene: "" };

  componentDidMount() {}

  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{this.props.topic}</h4>
          <p>{this.props.description}</p>
          <Input
            fluid
            focus
            placeholder="Put Preset Name Here)"
            onChange={(event) => {
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });

              let text = event.target.value;
              text = text.toLowerCase();
              this.setState({ searchName: text });
            }}
            value={this.state.searchName}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <Input
            fluid
            focus
            placeholder="Search query (Put AND/OR for logical search query)"
            onChange={(event) => {
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });

              let text = event.target.value;
              text = text.toLowerCase();
              text = text.replace(/ AND /gim, " AND ");
              text = text.replace(/ OR /gim, " OR ");
              const lucene = generateLucene(text);
              this.setState({ searchQuery: text, searchLucene: lucene });
            }}
            value={this.state.searchQuery}
          />
          <Segment style={{ color: "#89f536" }} size={"mini"} inverted>
            {this.state.searchLucene}
          </Segment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              this.state.searchName.length === 0 ||
              this.state.searchQuery.length === 0 ||
              generateLucene(this.state.searchQuery) === "Invalid!!"
            }
            variant={this.props.button1Style}
            onClick={() => {
              this.props.callBack1(
                this.state.searchName,
                this.state.searchQuery,
                this.state.searchLucene,
                true,
                null
              );
              this.props.callBack2();
            }}
          >
            {this.props.button1}
          </Button>
          <Button
            variant={this.props.button2Style}
            onClick={this.props.callBack2}
          >
            {this.props.button2}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddNewPresetModal;
