import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Card,
  Label,
  Icon,
  Table,
  Pagination,
  Header,
  Confirm,
} from "semantic-ui-react";
import { Container, Row, Col } from "react-bootstrap";

import { saveHumanLabel } from "../../actions";

class DetailTable extends React.Component<any> {
  state: any = {
    column: null,
    data: null,
    direction: null,
    isShowSuggest: false,
    numPage: 0,
    currentPage: 1,
    showingData: null,
    confirmedLabel: [],
  };
  componentDidMount() {
    const numPage = Math.ceil(this.props.dataCount / this.props.rowPerPage);
    const showingData = this.props.data;
    let confirmedLabel = this.props.data.map((ele: any) => ele.user_label);
    this.setState({
      data: this.props.data,
      numPage: numPage,
      showingData: showingData,
      confirmedLabel: confirmedLabel,
      currentPage: this.props.activePage,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      const numPage = Math.ceil(this.props.dataCount / this.props.rowPerPage);
      const showingData = this.props.data;
      this.setState({
        data: this.props.data,
        numPage: numPage,
        showingData: showingData,
        currentPage: this.props.activePage,
      });
    }
  }

  // handleSort = (clickedColumn) => () => {
  //   const { column, showingData, direction } = this.state;

  //   if (column !== clickedColumn) {
  //     this.setState({
  //       column: clickedColumn,
  //       showingData: _.sortBy(showingData, [clickedColumn]),
  //       direction: "ascending",
  //     });

  //     return;
  //   }

  //   this.setState({
  //     showingData: showingData.reverse(),
  //     direction: direction === "ascending" ? "descending" : "ascending",
  //   });
  // };

  handleSort = (clickedColumn: any) => () => {
    const { column, showingData, direction } = this.state;

    if (column !== clickedColumn) {
      const dir = "ascending";
      this.props.sortFunction(clickedColumn, dir);
      this.setState({
        isShowLoading: true,
        column: clickedColumn,
        direction: dir,
        filteredData: null,
      });

      return;
    }
    const dir = direction === "ascending" ? "descending" : "ascending";
    this.props.sortFunction(clickedColumn, dir);
    this.setState({
      isShowLoading: true,
      direction: dir,
      filteredData: null,
    });
  };


  renderClickToExpandSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="small" pointing="right">
          Click to expand the chart
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  changePage = (e: any, { activePage }: { activePage: any }) => {
    const showingData = this.state.filteredData;
    this.props.detailTableChangePage(activePage);
    this.setState({
      showingData: showingData,
      currentPage: activePage,
      filteredData: null,
    });
  };

  confirmSentiment = (id: any, value: any) => {
    const payload = {
      payload: {
        doc_id: this.props.data[id].doc_id,
        user_id: this.props.loginUser.data.userId,
        user_label: value.toLowerCase(),
        index: id,
      },
    };
    this.props.saveHumanLabel(payload);
  };

  renderConfirmedLabel = (user_label: any) => {
    if (user_label) {
      return (
        <Label
          color={"black"}
          key={"black"}
          style={{ marginTop: "15px", marginLeft: "15%", left: "100%" }}
        >
          {user_label.toUpperCase()}
        </Label>
      );
    }
    return null;
  };

  renderCorrectionTools = (id: any, user_label: any, sentiment: any) => {
    return (
      <div>
        <div style={{ display: "block", marginLeft: "20%", left: "100%" }}>
          <Icon
            name={user_label ? "check circle" : "check circle outline"}
            color={user_label ? "green" : "green"}
            size="large"
            style={user_label ? null : { cursor: "pointer" }}
            onClick={() => {
              this.confirmSentiment(id, sentiment);
            }}
            disabled={user_label ? true : false}
          ></Icon>
          <div className="sentimentDropdown" style={{ float: "right" }}>
            <Icon
              name={user_label ? "edit outline" : "times circle outline"}
              color={user_label ? "orange" : "red"}
              size="large"
              style={{ cursor: "pointer" }}
            ></Icon>
            <div className="sentimentDropdown-content">
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "positive");
                }}
              >
                Positive
              </a>
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "negative");
                }}
              >
                Negative
              </a>
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "neutral");
                }}
              >
                Neutral
              </a>
            </div>
          </div>
        </div>
        {this.renderConfirmedLabel(user_label)}
      </div>
    );
  };

  renderClass(product: any, channel: any, complain: any) {
    return (
      <div>
        {product && product.length !== 0 ? (
          <Label as="a" image>
            <Icon color="orange" name="cube"></Icon>
            Product
            <Label.Detail>{product.join(",")}</Label.Detail>
          </Label>
        ) : null}
        {channel && channel.length !== 0 ? (
          <Label as="a" image>
            <Icon color="green" name="keyboard"></Icon>
            Channel
            <Label.Detail>{channel.join(",")}</Label.Detail>
          </Label>
        ) : null}
        {complain ? (
          <Label as="a" image>
            <Icon color="red" name="thumbs down"></Icon>
            Complain
          </Label>
        ) : null}
      </div>
    );
  }

  render() {
    const {
      column,
      data,
      direction,
      numPage,
      showingData,
      currentPage,
    } = this.state;
    if (showingData != null) {
      const displayingCount = showingData.length;
      return (
        <Card fluid style={{ marginBottom: "15px" }}>
          <Card.Content>
            <Card.Header>
              Detail
              <div
                style={{
                  display: "inline-block",
                  float: "right",
                }}
              >
                {this.renderClickToExpandSuggestion()}
                <Icon
                  name="expand"
                  floated="right"
                  size="small"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={this.showSuggestion}
                  onMouseLeave={this.hideSuggestion}
                  onClick={() => {
                    this.props.setToggleShowBigTableWithFilter({
                      source: this.props.dispSources,
                      related: this.props.dispTargets,
                      sentiment: this.props.dispSentiments,
                    });
                  }}
                />
              </div>
            </Card.Header>
            <Card.Meta>
              Table shows the detail of each mention (Click the header to
              activate sorting)
            </Card.Meta>
          </Card.Content>
          <Card.Content>
            <Container>
              <Row>
                <Col lg={5} md={5} xs={12}>
                  <Header as="h4">
                    Now displaying:
                    {` ${displayingCount} of ${this.props.dataCount}`}
                  </Header>
                </Col>
                <Col lg={7} md={7} xs={12}>
                  <Pagination
                    activePage={currentPage}
                    totalPages={numPage}
                    onPageChange={this.changePage as any}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      overflow: "auto",
                      width: "100%",
                      height: "50vh",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <Table sortable celled padded selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={column === "id" ? direction : null}
                          // onClick={this.handleSort("id")}
                          >
                            #
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "date" ? direction : null}
                            onClick={this.handleSort("date")}
                          >
                            Date
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "source" ? direction : null}
                            onClick={this.handleSort("source")}
                          >
                            Source
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "related" ? direction : null}
                            onClick={this.handleSort("related")}
                          >
                            Related
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "text" ? direction : null}
                            onClick={this.handleSort("text")}
                          >
                            Text
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "sentiment" ? direction : null}
                            onClick={this.handleSort("sentiment")}
                          >
                            Sentiment
                          </Table.HeaderCell>
                          <Table.HeaderCell>Correction</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {_.map(
                          showingData,
                          ({
                            id,
                            date,
                            source,
                            related,
                            text,
                            sentiment,
                            src_id,
                            src_ids,
                            user_label,
                            old_sentiment,
                            cat_product,
                            cat_channel,
                            complain,
                          }) => (
                            <Table.Row key={`${source}_${id}`}>
                              <Table.Cell>
                                {(currentPage - 1) * this.props.rowPerPage +
                                  id +
                                  1}
                              </Table.Cell>
                              <Table.Cell>{date}</Table.Cell>
                              <Table.Cell>
                                <a
                                  href={`https://www.${source}.com${source === "pantip" ? "/topic" : ""
                                    }/${source === "facebook" || source === "pantip"
                                      ? src_ids.split("_")[
                                      src_ids.split("_").length - 1
                                      ]
                                      : src_ids
                                    }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {source}
                                </a>
                              </Table.Cell>
                              <Table.Cell>{related.toUpperCase()}</Table.Cell>
                              <Table.Cell>
                                {text}
                                {this.renderClass(
                                  cat_product,
                                  cat_channel,
                                  complain
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {old_sentiment ? (
                                  <Icon name="check" color="green"></Icon>
                                ) : null}
                                {sentiment}
                              </Table.Cell>
                              <Table.Cell>
                                {this.renderCorrectionTools(
                                  id,
                                  user_label,
                                  sentiment
                                )}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Content>
        </Card>
      );
    }
    return null;
  }
}

const mapStateToProps = (states: any) => {
  return {
    loginUser: states.loginUser,
  };
};

export default connect(mapStateToProps, {
  saveHumanLabel,
})(DetailTable);
