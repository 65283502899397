import React, { useEffect, useLayoutEffect, useState } from "react";

// Components
import { Header, Icon, Button, List, Pagination, Segment, Table, Loader, Modal } from "semantic-ui-react";
import { DateRangePicker } from "react-date-range";
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { DateRangeInterface } from "../models/date-range";
import { PageArgsInterface, PageOffsetInterface } from "../models/data-table";

// 3rd-Party Actions (Custom Hooks, States, etc.)

// Styles

export const DataTable = ({ ...props }: {
	columns: NodeJS.Dict<any>[],
	data: NodeJS.Dict<any>[],
	// for API call on page change. If this prop is set, it is service-bound.
	paginationService?: ({ pageOffset, data }: {
		pageOffset: number,
		data?: NodeJS.Dict<any>[]
	}) => Promise<any>,
	pageLength?: number,	// aka rows per page
	totalDataLength?: number,	// service-bound only
	rowComponents?: (React.ReactElement<any> | React.ReactElement<any>[]),
	rowClick?: (event?: Event, index?: number) => any,	// row click event handler
	rowProps?: NodeJS.Dict<any> | NodeJS.Dict<any>[],
	[key: string]: any
}) => {
	const [pageOffset, setPageOffset] = useState(0);
	const [pageData, setPageData] = useState<NodeJS.Dict<any>[]>([]);
	const [changingPage, setChangingPage] = useState(false);

	const loadingModal = useLoadingModal();

	const changePage = async (offset: number) => {
		setPageOffset(offset);
		if (props.paginationService) {
			setChangingPage(true);
			loadingModal.toggle(true);

			await props.paginationService({
				pageOffset: offset,
				data: props.data
			});

			setChangingPage(false);
			loadingModal.toggle(false);
		}
		else
			setPageData(props.data.slice(offset, props.pageLength ? offset + props.pageLength : undefined));
	};
	const externalDataChange = () => {
		if (props.paginationService) {
			setPageData(props.data);
			if (!changingPage)
				setPageOffset(0);
		}
		else
			setPageData(props.data.slice(0, props.pageLength));
	}

	useLayoutEffect(() => { externalDataChange(); }, [props.data]);
	useLayoutEffect(() => { setPageOffset(0); }, [props.pageLength]);

	const createRowComponents = (rowIndex: number) => {
		if (props.rowComponents)
			if (!Array.isArray(props.rowComponents))
				return (
					<Table.Cell>
						{React.cloneElement(props.rowComponents)}
					</Table.Cell>
				);
			else
				return props.rowComponents.map((e, i) => (
					<Table.Cell key={i}>
						{React.cloneElement(e, { rowIndex: rowIndex })}
					</Table.Cell>
				));
	};
	const createDataRow = (data:NodeJS.Dict<any>[]) => {
		return data.map((e, i) => (
			<Table.Row
				key={i}
				onClick={(evt: Event) => props.rowClick ? props.rowClick(evt, i + pageOffset) : undefined}
				{
					...props.rowProps ?
						Array.isArray(props.rowProps) ?
							props.rowProps[i] : props.rowProps
					: ''
				}
			>
				{props.columns.map((col, i) => <Table.Cell key={i}>{e[Object.keys(col)[0]]}</Table.Cell>)}
				{props.rowComponents ? createRowComponents(i + pageOffset): ''}
			</Table.Row>
		));
	};

	return (
		<Table
			/*{...props as
				Exclude<
					NodeJS.Dict<any>,
					'columns' |
					'data' |
					'paginationService' |
					'pageLength' |
					'totalDataLength' |
					'rowComponents' |
					'rowClick'
				>
			}*/
		>
			<Table.Header>
				<Table.Row>
					{props.columns.map((e, i) =>
						<Table.HeaderCell key={i}>{Object.values(e)[0]}</Table.HeaderCell>
					)}
					<Table.HeaderCell></Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{createDataRow(pageData)}
			</Table.Body>
			{ props.pageLength ?
				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell colSpan={99}>
							<Pagination
								activePage={(() => {
									// console.log(Math.floor(pageOffset / props.pageLength) + 1);
									return Math.floor(pageOffset / props.pageLength) + 1;
								})()}
								boundaryRange={1}
    							siblingRange={1}
								totalPages={Math.ceil((props.totalDataLength ? props.totalDataLength : props.data.length) / props.pageLength)}
								floated="right"
								onPageChange={(e, data) => {
									// console.log(data);
									if (props.pageLength) {
										const offset = ((data.activePage as number) - 1) * props.pageLength;
										changePage(offset);
									}
								}}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer> :
				''
			}
		</Table>
	);
};

export const SearchPanelPlaceholder = () => (
	<Segment placeholder>
		<Header as="h2" icon>
			<Icon name="search"/>
			<i>[Insert a search form here]</i>
		</Header>
	</Segment>
);

export const LoadingOverlay = () => <Loader active inline='centered'/>;

type ElementTree = JSX.Element | JSX.Element[] | ElementTree[];

export const TreeView = ({ components, collapsible, nested, ...props }: {
	components: ElementTree | ElementTree[],
	collapsible?: boolean,
	nested?: boolean,
	className?: string
}) => {
	const [folded, setFold] = useState(false);

	const listContents = (elem: ElementTree | ElementTree[], key: number) => Array.isArray(elem) ?
		(
			<div key={key}>
				{
					collapsible ?
						<>
							<Button
								className="tree-view-fold-btn"
								size="small"
								compact
								icon={folded ? 'caret right': 'caret down'}
								onClick={() => setFold(!folded)}
							/>
							<span hidden={!folded}>...</span>
						</> :
						''
				}
				<TreeView
					className={(collapsible && folded) ? ' hidden': ''}
					components={elem}
					collapsible={collapsible}
					nested
				/>
			</div>
		) :
		elem
	;

	return nested ? (
		<List.List className={props.className ? (props.className + ' tree-view item'): ' tree-view item'}>
			<List.Content>
				{Array.isArray(components) ? components.map(listContents): components}
			</List.Content>
		</List.List>
	) :
	(
		<List className={props.className ? (props.className + ' tree-view'): ' tree-view'}>
			<List.Content>
				{Array.isArray(components) ? components.map(listContents): components}
			</List.Content>
		</List>
	);
};

export const DatePickerButton = (
	props: React.PropsWithChildren<{
	modalLabel?: string | JSX.Element,
	range: { get: DateRangeInterface, set: (p: DateRangeInterface) => void },
	defaultRange: DateRangeInterface,
	onConfirm?: Function,
	confirmButtonContent?: string | JSX.Element,
	[key:string]: any
	}>
) => {
	const [dateModalVisible, toggleDateModal] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(props.defaultRange);
	const [prevDateRange, setPrevDateRange] = useState<DateRangeInterface>(props.defaultRange);

	const selectDateRange = (inputRange: any) => {
		const firstRange = Object.values(inputRange)[0] as DateRangeInterface;
		if (firstRange.startDate)
			setSelectedDateRange({
				startDate: firstRange.startDate,
				endDate: firstRange.endDate
			});
	};

	const confirmDateRange = () => {
		props.range.set(Object.assign({}, selectedDateRange));
		toggleDateModal(false);
	};

	const cancelDateRange = () => {
		setSelectedDateRange(prevDateRange);
		toggleDateModal(false);
	};

	useEffect(() => {
		if (dateModalVisible)
			setPrevDateRange(selectedDateRange);
	}, [dateModalVisible]);

	useEffect(() => {
		if (props.onConfirm)
			props.onConfirm();
	}, [props.range.get])

	return <>
		<Button
			className="date-picker-button"
			icon="calendar"
			primary
			content={props.children}
			onClick={() => toggleDateModal(true)}
		/>
		<Modal
			className="date-picker-modal"
			open={dateModalVisible}
			closeOnDimmerClick={false}
		>
			<Modal.Header>{props.modalLabel ? props.modalLabel: 'Select Date'}</Modal.Header>
			<Modal.Content>
				<DateRangePicker
					ranges={[selectedDateRange]}
					onChange={selectDateRange}
					{...props}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={cancelDateRange} floated="left" color="red">Close</Button>
				<Button onClick={confirmDateRange} color="green">{props.confirmButtonContent ? props.confirmButtonContent: 'Set'}</Button>
			</Modal.Actions>
		</Modal>
	</>
};