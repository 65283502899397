import React from "react";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import {
  Table,
  Pagination,
  Header,
  Input,
  Icon,
  Label,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";
import { Modal, Button as BootstrapButton, Container, Row, Col } from "react-bootstrap";

import { saveHumanLabel } from "../../actions";

const OPTIONS = [
  { key: "date", text: "Date", value: "date" },
  { key: "source", text: "Source", value: "source" },
  { key: "related", text: "Related", value: "related" },
  { key: "text", text: "Text", value: "text" },
  { key: "sentiment", text: "Sentiment", value: "sentiment" },
  { key: "ALL", text: "ALL", value: "ALL" },
];

class BigTable extends React.Component<any> {
  state: any = {
    column: null,
    data: null,
    filteredData: null,
    direction: null,
    isShowSuggest: false,
    numPage: 0,
    currentPage: 1,
    showingData: null,
    searchFields: [],
    searchQuery: "",
    isShowLoading: false,
    isDownloaded: true,
  };
  componentDidMount() {
    let data = _.cloneDeep(this.props.data);
    const filter = this.props.filter;
    let searchQuery = "";
    if (filter !== null) {
      if (filter.sub_query !== null) {
        searchQuery = filter.sub_query;
      }
    }
    const numPage = Math.ceil(this.props.dataCount / this.props.rowPerPage);
    const showingData = data;
    this.setState({
      data: data,
      dataLen: this.props.dataCount,
      numPage: numPage,
      showingData: showingData,
      searchFields: _.uniq(_.map(OPTIONS, "value")).slice(0, -1),
      filteredData: data,
      currentPage: this.props.activePage,
      searchQuery: searchQuery,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props !== prevProps) {
      if (prevProps.data !== this.props.data) {
        let filteredData = _.cloneDeep(this.props.data);
        const numPage = Math.ceil(this.props.dataCount / this.props.rowPerPage);
        const showingData = filteredData;
        if (this.props.csvDownloadURL && !this.state.isDownloaded) {
          // console.log(this.props.csvDownloadURL);
          window.open(this.props.csvDownloadURL.url_to_download);
        }
        this.setState({
          isDownloaded: true,
          isShowLoading: false,
          data: this.props.data,
          showingData: showingData,
          numPage: numPage,
          filteredData: filteredData,
          currentPage: this.props.activePage,
          dataLen: this.props.dataCount,
        });
      }
    }
  }

  handleSort = (clickedColumn: any) => () => {
    const { column, showingData, direction } = this.state;

    if (column !== clickedColumn) {
      const dir = "ascending";
      this.props.sortFunction(clickedColumn, dir);
      this.setState({
        isShowLoading: true,
        column: clickedColumn,
        direction: dir,
        filteredData: null,
      });

      return;
    }
    const dir = direction === "ascending" ? "descending" : "ascending";
    this.props.sortFunction(clickedColumn, dir);
    this.setState({
      isShowLoading: true,
      direction: dir,
      filteredData: null,
    });
  };

  changePage = (e: any, { activePage }: { activePage: any }) => {
    const showingData = this.state.filteredData;
    this.props.bigTableChangePage(activePage);
    this.setState({
      isShowLoading: true,
      currentPage: activePage,
      filteredData: null,
    });
  };

  confirmSentiment = (id: any, value: any) => {
    const payload = {
      payload: {
        doc_id: this.props.data[id].doc_id,
        user_id: this.props.loginUser.data.userId,
        user_label: value.toLowerCase(),
        index: id,
      },
    };
    this.props.saveHumanLabel(payload);
  };

  renderConfirmedLabel = (user_label: any) => {
    if (user_label) {
      return (
        <Label
          color={"black"}
          key={"black"}
          style={{ marginTop: "15px", marginLeft: "15%", left: "100%" }}
        >
          {user_label.toUpperCase()}
        </Label>
      );
    }
    return null;
  };

  renderCorrectionTools = (id: any, user_label: any, sentiment: any) => {
    return (
      <div>
        <div style={{ display: "block", marginLeft: "20%", left: "100%" }}>
          <Icon
            name={user_label ? "check circle" : "check circle outline"}
            color={user_label ? "green" : "green"}
            size="large"
            style={user_label ? null : { cursor: "pointer" }}
            onClick={() => {
              this.confirmSentiment(id, sentiment);
            }}
            disabled={user_label ? true : false}
          ></Icon>
          <div className="sentimentDropdown" style={{ float: "right" }}>
            <Icon
              name={user_label ? "edit outline" : "times circle outline"}
              color={user_label ? "orange" : "red"}
              size="large"
              style={{ cursor: "pointer" }}
            ></Icon>
            <div className="sentimentDropdown-content">
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "positive");
                }}
              >
                Positive
              </a>
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "negative");
                }}
              >
                Negative
              </a>
              <a
                href="#"
                onClick={() => {
                  this.confirmSentiment(id, "neutral");
                }}
              >
                Neutral
              </a>
            </div>
          </div>
        </div>
        {this.renderConfirmedLabel(user_label)}
      </div>
    );
  };

  filter = () => {
    this.props.filterFunction(this.state.searchQuery);
    this.setState({
      isShowLoading: true,
      currentPage: 1,
      filteredData: null,
    });
  };

  renderTable = () => {
    const {
      column,
      direction,
      numPage,
      showingData,
      dataLen,
      currentPage,
    } = this.state;
    if (showingData != null) {
      const displayingCount = showingData.length;
      return (
        <Container>
          <Row>
            <Col lg={3} md={3} xs={12}>
              <Input
                placeholder="Search"
                fluid
                onChange={(event) => {
                  this.setState({
                    searchQuery: event.target.value,
                  });
                }}
                value={this.state.searchQuery}
              />
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Button
                as={BootstrapButton}
                icon
                variant="dark"
                style={{ width: "100%", height: "100%" }}
                onClick={this.filter}
              >
                <Icon size="large" name="filter" />
                Filter
              </Button>
            </Col>
            <Col lg={2} md={2} xs={12}>
              {/* <CsvDownloader
                datas={this.state.filteredData}
                filename={`data_${new Date()}.csv`}
              > */}
              <Button
                as={BootstrapButton}
                icon
                secondary
                style={{ width: "100%", height: "100%" }}
                onClick={() => {
                  this.setState({ isShowLoading: true, isDownloaded: false });
                  this.props.downloadCSV();
                }}
              >
                <Icon size="small" name="save" />
                Save Data as CSV
              </Button>
              {/* </CsvDownloader> */}
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col lg={6} md={6} xs={12}>
              <Header as="h4">
                Now displaying:
                {` ${displayingCount} of ${dataLen}`}
              </Header>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Pagination
                totalPages={numPage}
                onPageChange={this.changePage as any}
                activePage={currentPage}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  overflow: "auto",
                  width: "100%",
                  height: "50vh",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                <Table sortable celled padded selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={column === "id" ? direction : null}
                      >
                        #
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "date" ? direction : null}
                        onClick={this.handleSort("date")}
                      >
                        Date
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "source" ? direction : null}
                        onClick={this.handleSort("source")}
                      >
                        Source
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "related" ? direction : null}
                        onClick={this.handleSort("related")}
                      >
                        Related
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "text" ? direction : null}
                        onClick={this.handleSort("text")}
                      >
                        Text
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "sentiment" ? direction : null}
                        onClick={this.handleSort("sentiment")}
                      >
                        Sentiment
                      </Table.HeaderCell>
                      <Table.HeaderCell>Correction</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(
                      showingData,
                      ({
                        id,
                        date,
                        source,
                        related,
                        text,
                        sentiment,
                        src_id,
                        src_ids,
                        user_label,
                        old_sentiment,
                        cat_product,
                        cat_channel,
                        complain,
                      }) => {
                        let searchWords: any = [];
                        if (this.props.filter) {
                          if (this.props.filter.keyWord) {
                            searchWords = [this.props.filter.keyWord];
                          } else if (this.props.filter.hashtag) {
                            searchWords = [this.props.filter.hashtag];
                          }
                        }
                        searchWords.push(this.state.searchQuery);
                        return (
                          <Table.Row key={`${source}_${id}`}>
                            <Table.Cell>
                              {(currentPage - 1) * this.props.rowPerPage +
                                id +
                                1}
                            </Table.Cell>
                            <Table.Cell>{date}</Table.Cell>
                            <Table.Cell>
                              <a
                                href={`https://www.${source}.com${
                                  source === "pantip" ? "/topic" : ""
                                }/${
                                  source === "facebook" || source === "pantip"
                                    ? src_ids.split("_")[
                                        src_ids.split("_").length - 1
                                      ]
                                    : src_ids
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {source}
                              </a>
                            </Table.Cell>
                            <Table.Cell>{related.toUpperCase()}</Table.Cell>
                            <Table.Cell>
                              <Highlighter
                                highlightStyle={{ backgroundColor: "#ffd54f" }}
                                highlightClassName="YourHighlightClass"
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={text}
                              />
                              {this.renderClass(
                                cat_product,
                                cat_channel,
                                complain
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {old_sentiment ? (
                                <Icon name="check" color="green"></Icon>
                              ) : null}
                              {sentiment}
                            </Table.Cell>
                            <Table.Cell>
                              {this.renderCorrectionTools(
                                id,
                                user_label,
                                sentiment
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )}
                  </Table.Body>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderClass(product: any, channel: any, complain: any) {
    return (
      <div>
        {product && product.length !== 0 ? (
          <Label as="a" image>
            <Icon color="orange" name="cube"></Icon>
            Product
            <Label.Detail>{product.join(",")}</Label.Detail>
          </Label>
        ) : null}
        {channel && channel.length !== 0 ? (
          <Label as="a" image>
            <Icon color="green" name="keyboard"></Icon>
            Channel
            <Label.Detail>{channel.join(",")}</Label.Detail>
          </Label>
        ) : null}
        {complain ? (
          <Label as="a" image>
            <Icon color="red" name="thumbs down"></Icon>
            Complain
          </Label>
        ) : null}
      </div>
    );
  }

  render() {
    const filter = this.props.filter;
    let filterText = "";
    if (filter !== null) {
      Object.keys(filter).forEach((key) => {
        filterText += filter[key] + ",";
      });
    }
    filterText = filterText.slice(0, -1);
    const isShowLoading = this.state.isShowLoading;
    return (
      <Modal
        show={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Dimmer active={isShowLoading}>
          <Loader>Loading</Loader>
        </Dimmer>
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ width: "100%" }}
          >
            {filter === null
              ? "Detail"
              : `Detail (${filterText.toUpperCase()})`}
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              <Icon
                name="compress"
                size="small"
                style={{ cursor: "pointer" }}
                onClick={this.props.closeBigTable}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <div style={{ width: "100%", height: "100%", marginTop: "15px" }}>
          {this.renderTable()}
        </div>
        <Modal.Footer>
          <BootstrapButton variant="danger" onClick={this.props.closeBigTable}>
            Close
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (states: any) => {
  return {
    loginUser: states.loginUser,
  };
};

export default connect(mapStateToProps, {
  saveHumanLabel,
})(BigTable);
