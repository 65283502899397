import { GET_LOGIN_USER } from "../actions/types";

export default function (
  state = { status: "Initializing", data: null },
  action: any
) {
  switch (action.type) {
    case GET_LOGIN_USER:
      return action.payload;
    default:
      return state;
  }
}
