import React, { useLayoutEffect, useState } from "react";

// Styles
import '../css/operation.css';

// Components
import { Button, Container, Dropdown, Form, Header, Icon, Label, Popup, Rating, Segment, Tab} from "semantic-ui-react";
import { DataTable, DatePickerButton } from "./building-blocks";

// 3rd-Party Actions (Custom Hooks, States, etc.)
import { useRecoilState, useRecoilValue } from "recoil";
import {
	operationActionModalStack as actionModalStack,
	operationActionDataStack as actionDataStack,
	operationPostsPlatform as postsPlatform
} from "../recoil-atoms/operation";
import { SocialPostDataTableInterface, SocialPostRequestInterface } from "../models/operation";
import { useViewAllPosts } from "../hooks/services/operation-posts";
import { useDateRange } from "../hooks/operation-date-range";
import { stackAtomPush } from "../utils/recoil-helpers";
import { CloneAndAssign } from "../utils/object-helpers";
import { SOCIAL_PLATFORMS, TABLE_PAGELENGTH } from "../configs/operation";
import { PageArgsInterface } from "../models/data-table";
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { useEffect } from "react";
import { getRemainingSLAString, toDateString } from "../utils/string-formatters";

const POSTS_DEFAULT_REQUEST: SocialPostRequestInterface = {
	q: '',
	from: new Date(new Date().getTime() - 518400000),
	to: new Date(),
	offset_start: 0,
	offset_end: TABLE_PAGELENGTH - 1,
	source: SOCIAL_PLATFORMS[0].id
};

const PostActionContextMenu = (props: any) => {
	const [modalStack, setModalStack] = useRecoilState(actionModalStack);
	const [dataStack, setDataStack] = useRecoilState(actionDataStack);
	const posts = useViewAllPosts();

	// actions
	const viewRelatedEntries = () => {
		stackAtomPush(dataStack, setDataStack, {
			action: 'viewRelatedPostEntries',
			data: posts.get.data[props.rowIndex]
		});
	};
	const viewRelatedTickets = () => {
		stackAtomPush(dataStack, setDataStack, {
			action: 'viewRelatedTickets',
			data: posts.get.data[props.rowIndex]
		});
	};
	const requestTicketCreation = () => {
		stackAtomPush(dataStack, setDataStack, {
			action: 'requestTicketCreation',
			data: posts.get.data[props.rowIndex]
		});
	};
	const createTicket = () => {
		stackAtomPush(dataStack, setDataStack, {
			action: 'createTicket',
			data: posts.get.data[props.rowIndex]
		});
	};

	return (
		<Dropdown className="operation-data-dropdown" icon="bars" direction="left">
			<Dropdown.Menu>
				<Dropdown.Header icon="clone" content="Post"/>
				<Dropdown.Item text="View Related Entries" onClick={viewRelatedEntries}/>
				<Dropdown.Divider/>
				<Dropdown.Header icon="list alternate" content="Ticket"/>
				<Dropdown.Item text="View Related Tickets" onClick={viewRelatedTickets}/>
				<Dropdown.Item text="Request Ticket Creation" onClick={requestTicketCreation}/>
				<Dropdown.Item text="Create Ticket (admin)" onClick={createTicket}/>
			</Dropdown.Menu>
		</Dropdown>
	);
};
const PostInfoPopup = () => (
	<Segment basic className="operation-post-info">
		<Popup
			header="Quick Guides: Posts"
			content={
				<ul>
					<li>Filters are also applied upon setting a new date range.</li>
					<li>Hovering over shortened text reveals full text in a tooltip.</li>
					<li>The remaining SLA time is formatted in <b>h:mm:ss</b>.</li>
					<li>In some channels, rows are colorized depending on the remaining SLA time.
						<ul>
							<li><div className="sla-green sample"/>: &gt; 2 Hours</li>
							<li><div className="sla-lime sample"/>: &gt; 1 Hour</li>
							<li><div className="sla-yellow sample"/>: &gt; 30 Minutes</li>
							<li><div className="sla-orange sample"/>: &gt; 15 Minutes</li>
							<li><div className="sla-red sample"/>: Last 15 Minutes</li>
							<li><div className="sla-grey sample"/>: Over Due</li>
						</ul>
					</li>
				</ul>
			}
			trigger={(<Button circular icon="info"/>)}
			position="bottom right"
			size="small"
		/>
	</Segment>
);
const PostSearchForm = () => {
	const loadingModal = useLoadingModal();
	const dateRange = useDateRange({
		startDate: POSTS_DEFAULT_REQUEST.from as Date,
		endDate: POSTS_DEFAULT_REQUEST.to as Date
	});
	const posts = useViewAllPosts();

	const [query, setQuery] = useState('');
	const [initialized, setInitialized] = useState(false);

	const applyPostFilters = async () => {
		loadingModal.toggle(true);

		let payload: NodeJS.Dict<any> = {
			q: query,
			from: dateRange.get.startDate,
			to: dateRange.get.endDate,
			offset_start: POSTS_DEFAULT_REQUEST.offset_start,
			offset_end: POSTS_DEFAULT_REQUEST.offset_end
		};
		if (!posts.get.total && initialized) {
			setInitialized(true);
			payload.source = POSTS_DEFAULT_REQUEST.source;
		}
		await posts.request(payload, true);

		loadingModal.toggle(false);
	};

	return (
		<Segment secondary>
			<Header as="h2">Search Reviews</Header>
			<Form className="operation-post-search-form">
				<Form.Group>
					<Form.Input fluid placeholder="Query..." onChange={e => setQuery(e.target.value)} />
					<Form.Field className="operation-posts-date-label">
						<Label pointing="right">
							{'From: ' + dateRange.get.startDate.toLocaleDateString()}<br/>
							{'To: ' + dateRange.get.endDate.toLocaleDateString()}
						</Label>
					</Form.Field>
					<Form.Field>
						<DatePickerButton
							defaultRange={{
								startDate: POSTS_DEFAULT_REQUEST.from as Date,
								endDate: POSTS_DEFAULT_REQUEST.to as Date
							}}
							range={dateRange}
							onConfirm={applyPostFilters}
							confirmButtonContent={<><Icon name="search"/>Set & Search</>}
						>
							Date Range
						</DatePickerButton>
					</Form.Field>
					<Form.Button color="green" icon="search" content="Search" onClick={() => applyPostFilters()}/>
				</Form.Group>
			</Form>
		</Segment>
	);
};
const PostResultsInfo = () => {
	const posts = useViewAllPosts();
	const loadingModal = useLoadingModal();

	const reload = async () => {
		loadingModal.toggle(true);

		await posts.request({}, true);

		loadingModal.toggle(false);
	};

	return (
		<Segment basic className="operation-results-info">
			<b>Total Results: </b>
			<Label>{posts.get.total}</Label>
			<Button basic circular floated="right" icon="refresh" onClick={reload}/>
		</Segment>
	);
};
const PostPlatformButtonGroup = () => {
	const loadingModal = useLoadingModal();
	const posts = useViewAllPosts();

	const [platform, setPlatform] = useRecoilState(postsPlatform);

	const setPlatformButtonActive = (id: string) => platform == id ? 'active ' + id : '';
	const onPlatformChange = async (id: string) => {
		if (platform !== id) {
			loadingModal.toggle(true);

			setPlatform(id);
			await posts.request({
				source: id,
				offset_start: POSTS_DEFAULT_REQUEST.offset_start,
				offset_end: POSTS_DEFAULT_REQUEST.offset_end
			}, true);
			
			loadingModal.toggle(false);
		}
	};

	return (
		<Button.Group className='operation-platform-buttons' attached='top' color='grey' labeled icon>
			{SOCIAL_PLATFORMS.map((p, i) =>
				<Button
					key={i}
					className={setPlatformButtonActive(p.id)}
					onClick={() => onPlatformChange(p.id)}
					icon={p.icon}
					content={p.name}
				/>
			)}
		</Button.Group>
	);
};
const PostDataTable = () => {
	const loadingModal = useLoadingModal();
	const posts = useViewAllPosts();
	const platform = useRecoilValue(postsPlatform);

	const [tableData, setTableData] = useState<SocialPostDataTableInterface[]>([]);
	const [pageLength, setPageLength] = useState(TABLE_PAGELENGTH);
	const [tableColumns, setTableColumns] = useState([{}]);
	const [rowProps, setRowProps] = useState<NodeJS.Dict<any> | {}>({});

	const updateTableData = () => {
		if (posts.get.data) {
			setTableData(
				posts.get.data.map(e => Object.assign({}, e)).map(
					e => ({
						postDate: e.post_date.toLocaleString(),
						appVersion: e.mobile_version,
						title: e.title ? <div className="long-text" title={e.title}>{e.title}</div>: '',
						text: <div className="long-text" title={e.text}>{e.text}</div>,
						rating: <Rating title={e.rating + '/5'} rating={e.rating} maxRating={5} disabled />,
						reviewer: e.author,
						status: e.status,
						slaTimeRemaining: getRemainingSLAString(e.sla)
					})
				)
			);
			if (platform === 'play_store')	// Do not show row colors if it's 🍏
				setRowProps (
					posts.get.data.map(e => {
						if (e.sla > 7200)		// 2 hrs+
							return { className: 'sla-green' };
						else if (e.sla > 3600)	// 1 hr+
							return { className: 'sla-lime' };
						else if (e.sla > 1800)	// 30 min+
							return { className: 'sla-yellow' };
						else if (e.sla > 900)	// 15 min+
							return { className: 'sla-orange' };
						else if (e.sla > 0)	// 0+
							return { className: 'sla-red' };
						else
							return { className: 'sla-grey' };
					})
				);
			else
				setRowProps ({});
		}
	};
	const pageChangeHandler = async ({ pageOffset, data }: PageArgsInterface) => await posts.request({
		offset_start: pageOffset,
		offset_end: pageOffset + pageLength - 1,
	}, true);
	const changeTableColumns = () => {
		switch (platform) {
			case 'play_store':
				setTableColumns([
					{ postDate: 'Date' },
					{ appVersion: 'App Version' },
					{ text: 'Content' },
					{ rating: 'Rating' },
					{ reviewer: 'Reviewer' },
					{ status: 'Status' },
					{ slaTimeRemaining: 'Remaining SLA Time' }
				]);
				break;
			case 'app_store':
				setTableColumns([
					{ postDate: 'Date' },
					{ appVersion: 'App Version' },
					{ title: 'Title' },
					{ text: 'Content' },
					{ rating: 'Rating' },
					{ reviewer: 'Reviewer' },
					{ status: 'Status' }
				]);
				break;
		}
	};

	useLayoutEffect(() => { changeTableColumns(); }, [platform]);
	useLayoutEffect(() => { updateTableData(); }, [posts.get.data]);

	return (
		<Segment className="operation-data-table" basic>
			<DataTable
				attached="bottom"
				columns={tableColumns}
				data={tableData}
				rowComponents={(<a/>)}
				// rowClick={(e, i) => alert('yee' + i)}
				rowProps={rowProps}
				pageLength={pageLength}
				totalDataLength={posts.get.total}
				paginationService={pageChangeHandler}
				selectable
			/>
		</Segment>
	)
};

export const ViewPosts = () => (
	<Tab.Pane as={Container}>
		<PostInfoPopup/>
		<PostSearchForm/>
		<PostResultsInfo/>
		<PostPlatformButtonGroup/>
		<PostDataTable/>
	</Tab.Pane>
);