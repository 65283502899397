import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { addDays } from "date-fns";
import { SketchPicker } from "react-color";
import {
  Container as BootstrapContainer,
  Row,
  Col,
  Accordion,
  Card,
  CardColumns,
} from "react-bootstrap";
import { Header, Icon, List, Segment, Container } from "semantic-ui-react";

import {
  changePage,
  getStatData,
  saveSearch,
  getPreset,
  getConfiguration,
  deletePreset,
  saveConfiguration,
} from "../actions";

import PresetEditModal from "./PresetEditModal";
import AddNewPresetModal from "./AddNewPresetModal";
import ConfirmModal from "./ConfirmModal";

class Manage extends React.Component<any> {
  state: any = {
    colors: [],
    isShowEditModal: false,
    isShowAddModal: false,
    isShowDeleteConfirmModal: false,
    currentSelectedPreset: null,
    currentSelectedGroup: null,
    selectedColor: "",
    selectedBank: "",
    configuration: null,
  };
  

  componentDidMount() {
    this.props.showLoading();
    this.props.getConfiguration();
    this.props.getPreset();
    this.props.getStatData(
      addDays(new Date(), -1),
      new Date(),
      50,
      1,
      "1d",
      [],
      [],
      [],
      "*bbl*",
      [],
      [],
      [],
      this.props.loginUser.data.userId,
      []
    );
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      if (
        this.props.configuration !== null &&
        this.props.preset !== null &&
        this.props.data !== null
      ) {
        let newConfiguration = _.cloneDeep(this.props.configuration);
        this.props.data.respondPayload.options.bank.forEach((ele: any) => {
          if (!(ele in this.props.configuration.color)) {
            newConfiguration.color[ele] = "#000000";
          }
        });
        let bankKey = "";
        if (this.state.selectedBank === "") {
          bankKey = Object.keys(newConfiguration.color)[0];
        } else {
          bankKey = this.state.selectedBank;
        }
        this.setState({
          selectedColor: this.props.configuration.color[bankKey],
          selectedBank: bankKey,
          configuration: newConfiguration,
        });
        this.props.hideLoading();
      }
    }
  }

  renderBankColor = () => {
    return this.props.data.respondPayload.options.bank.map((ele: any, idx: any) => {
      const color = this.state.configuration.color[ele.toLowerCase()];
      return (
        <List.Item
          key={`bColor_${idx}`}
          style={
            this.state.selectedBank === ele
              ? { background: "#DEDEDE", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={() => {
            this.setState({
              selectedBank: ele,
              selectedColor: color,
            });
          }}
        >
          <Icon size="big" name="circle" style={{ color: color }}></Icon>
          <List.Content>
            <List.Header>{ele.toUpperCase()}</List.Header>
          </List.Content>
        </List.Item>
      );
    });
  };

  handleChangeComplete = (color: any) => {
    let payload = this.state.configuration;
    payload.color[this.state.selectedBank] = color.hex;
    this.props.saveConfiguration(payload);
    this.setState({ selectedColor: color.hex });
  };

  renderColorManagement = () => {
    if (
      this.state.configuration !== null &&
      this.props.preset !== null &&
      this.props.data !== null
    ) {
      return (
        <Container as={BootstrapContainer}>
          <Row>
            <Col>
              <List>
                <List.Item>
                  <List.Icon name="building" />
                  <List.Content>
                    <List.Header>Banks</List.Header>
                    <List.Description>Colors of banks</List.Description>
                    <List.List>{this.renderBankColor()}</List.List>
                  </List.Content>
                </List.Item>
              </List>
            </Col>
            <Col style={{ margin: "auto" }}>
              <SketchPicker
                width="70%"
                color={this.state.selectedColor}
                onChangeComplete={this.handleChangeComplete}
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  setAsDefaultPreset = (presetName: any) => {
    let configuration = this.state.configuration;
    configuration.defaultPreset = presetName;
    this.props.saveConfiguration(configuration);
  };

  renderNonBankSegment = (query: any, presetIdx: any) => {
    let splitQuery = query.lucene.split("|");
    return splitQuery.slice(0, -1).map((ele: any, groupIdx: any) => {
      return (
        <div>
          {ele.split(":")[1]}
          <Row>
            <Col lg={10}>
              <Segment style={{ color: "#89f536" }} size={"mini"} inverted>
                {ele.split(":")[0]}
              </Segment>
            </Col>
            <Col lg={2}>
              <Icon
                name="edit"
                size="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setToggleShowEditModalNonBank(presetIdx, groupIdx);
                }}
              />
              <Icon
                size="small"
                name="circle"
                style={{
                  color:
                    this.props.preset[presetIdx].color[
                      ele.split(":")[1].toLowerCase()
                    ],
                }}
              ></Icon>
            </Col>
          </Row>
        </div>
      );
    });
  };

  renderCard = () => {
    return this.props.preset.map((ele: any, idx: any) => {
      return (
        <Card
          bg="secondary"
          key={`preset_con_${idx}`}
          text="white"
          style={{ width: "90%", marginTop: "15px", marginBottom: "15px" }}
        >
          <Card.Header>
            {ele.searchName}
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              <Icon
                name={`flag ${
                  this.props.preset[idx].searchName ===
                  this.state.configuration.defaultPreset
                    ? ""
                    : "outline"
                }` as any}
                size="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setAsDefaultPreset(this.props.preset[idx].searchName);
                }}
              />
              {ele.isBankSearch ? (
                <Icon
                  name="edit"
                  size="small"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setToggleShowEditModal(idx);
                  }}
                />
              ) : null}
              <Icon
                name="delete"
                size="small"
                style={{ cursor: "pointer", color: "#d63a3a" }}
                onClick={() => {
                  this.setToggleShowConfirmDelete(idx);
                }}
              />
            </div>
          </Card.Header>
          <Card.Body>
            {ele.isBankSearch ? (
              <Card.Title> {ele.searchText} </Card.Title>
            ) : null}
            <Card.Text>
              {ele.isBankSearch ? (
                <Segment style={{ color: "#89f536" }} size={"mini"} inverted>
                  {ele.lucene}
                </Segment>
              ) : (
                <Container>{this.renderNonBankSegment(ele, idx)}</Container>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      );
    });
  };

  renderPresetConfiguration = () => {
    if (
      this.state.configuration !== null &&
      this.props.preset !== null &&
      this.props.data !== null
    ) {
      return <CardColumns>{this.renderCard()}</CardColumns>;
    }
    return null;
  };

  renderContent = () => {
    return (
      <Segment>
        <Header size="huge" style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Icon name="cog" size="huge" />
          Dashboard Management
        </Header>
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              style={{ cursor: "pointer" }}
            >
              <Icon name="paint brush" />
              Color management
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{this.renderColorManagement()}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              style={{ cursor: "pointer" }}
            >
              <Icon name="search" />
              Search query management
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.setToggleShowAddModal}
                >
                  <Icon size="big" name="plus" />
                  Add new preset
                </div>
                {this.renderPresetConfiguration()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Segment>
    );
  };

  setToggleShowEditModal = (selectedIndex: any) => {
    this.setState({
      isShowEditModal: !this.state.isShowEditModal,
      currentSelectedPreset: selectedIndex,
    });
  };

  setToggleShowEditModalNonBank = (presetIdx: any, groupIdx: any) => {
    this.setState({
      isShowEditModal: !this.state.isShowEditModal,
      currentSelectedPreset: presetIdx,
      currentSelectedGroup: groupIdx,
    });
  };

  setToggleShowConfirmDelete = (selectedIndex?: any) => {
    this.setState({
      isShowDeleteConfirmModal: !this.state.isShowDeleteConfirmModal,
      currentSelectedPreset: selectedIndex,
    });
  };

  renderEditModal = () => {
    if (this.state.isShowEditModal) {
      let desc = "";
      let searchText = "";
      let searchName = "";
      if (this.props.preset[this.state.currentSelectedPreset].isBankSearch) {
        searchName =
          this.props.preset[this.state.currentSelectedPreset].searchName;
        desc = this.props.preset[this.state.currentSelectedPreset].searchName;
        searchText =
          this.props.preset[this.state.currentSelectedPreset].searchText;
      } else {
        searchName =
          this.props.preset[this.state.currentSelectedPreset].searchName;
        desc =
          this.props.preset[this.state.currentSelectedPreset].searchName +
          " : " +
          this.props.preset[this.state.currentSelectedPreset].searchText
            .split("|")
            [this.state.currentSelectedGroup].split(":")[1];
        searchText = this.props.preset[
          this.state.currentSelectedPreset
        ].searchText
          .split("|")
          [this.state.currentSelectedGroup].split(":")[0];
      }
      return (
        <PresetEditModal
          size={"lg"}
          header={"Edit Preset"}
          topic={""}
          description={`You are now editing "${desc}"`}
          searchName={searchName}
          searchText={searchText}
          button1={"Save"}
          button2={"Cancel"}
          button1Style={"primary"}
          button2Style={"secondary"}
          callBack1={this.props.saveSearch}
          callBack2={this.setToggleShowEditModal}
          preset={this.props.preset[this.state.currentSelectedPreset]}
          group={this.state.currentSelectedGroup}
        ></PresetEditModal>
      );
    }
    return null;
  };

  confirmDeletePreset = () => {
    this.setToggleShowConfirmDelete();
    this.props.deletePreset(
      this.props.preset[this.state.currentSelectedPreset].searchName
    );
  };

  renderDeleteModal = () => {
    if (this.state.isShowDeleteConfirmModal) {
      return (
        <ConfirmModal
          size={"sm"}
          header={"Delete Preset"}
          topic={""}
          description={`Do you really want to delete preset name "${
            this.props.preset[this.state.currentSelectedPreset].searchName
          }"?`}
          button1={"Delete"}
          button2={"Cancel"}
          button1Style={"danger"}
          button2Style={"secondary"}
          callBack1={this.confirmDeletePreset}
          callBack2={this.setToggleShowConfirmDelete}
        ></ConfirmModal>
      );
    }
    return null;
  };

  setToggleShowAddModal = () => {
    this.setState({
      isShowAddModal: !this.state.isShowAddModal,
    });
  };

  renderAddModal = () => {
    if (this.state.isShowAddModal) {
      return (
        <AddNewPresetModal
          size={"lg"}
          header={"Add Preset"}
          topic={""}
          description={`Adding New Preset`}
          button1={"Save"}
          button2={"Cancel"}
          button1Style={"primary"}
          button2Style={"secondary"}
          callBack1={this.props.saveSearch}
          callBack2={this.setToggleShowAddModal}
        ></AddNewPresetModal>
      );
    }
    return null;
  };

  render() {
    return (
      <Container as={BootstrapContainer} /*text*/>
        {this.renderEditModal()}
        {this.renderAddModal()}
        {this.renderDeleteModal()}
        <Row style={{ marginTop: "80px" }}>
          <Col lg={12} md={12} xs={12}>
            {this.renderContent()}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (states: any) => {
  return {
    currentPage: states.page.currentPage,
    loginUser: states.loginUser,
    data: states.statData,
    preset: states.preset,
    configuration: states.configuration,
  };
};

export default connect(mapStateToProps, {
  changePage,
  getStatData,
  saveSearch,
  getPreset,
  getConfiguration,
  deletePreset,
  saveConfiguration,
})(Manage);
