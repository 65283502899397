interface SocialPlatformInterface {
	id: string,
	name: string,
	icon: string
}
export const SOCIAL_PLATFORMS: SocialPlatformInterface[] = [
	{ id: 'play_store', name: 'Google Play Store', icon: 'google play' },
	{ id: 'app_store', name: 'App Store', icon: 'apple' },
];

export const TABLE_PAGELENGTH = 20;