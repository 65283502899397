import { atom } from "recoil";
import {
	SocialEntryInterface,
	SocialPostRequestInterface,
	SocialPostResponseInterface,
	SocialTicketInterface
} from "../models/operation";
import { SOCIAL_PLATFORMS } from "../configs/operation";

export const operationActionModalStack = (() => {
	interface operationActionModalStackInterface {
		header?: string | JSX.Element | JSX.Element[],
		content: string | JSX.Element | JSX.Element[],
		actions?: JSX.Element | JSX.Element[],
		[key: string]: any
	}

	return atom({
		key: 'operationActionModalStack',
		default: [] as operationActionModalStackInterface[]
	});
})();

export const operationActionDataStack = (() => {
	interface operationActionDataStackInterface {
		action: string,
		data?: any
		[key: string]: any
	}

	return atom({
		key: 'operationActionDataStack',
		default: [] as operationActionDataStackInterface[]
	});
})();

export const operationPostsRequest = atom({ key: 'operationPostsRequest', default: {} as Partial<SocialPostRequestInterface> });
export const operationPostsResponse = atom({ key: 'operationPostsResponse', default: {} as SocialPostResponseInterface });
export const operationPostsPlatform = atom({ key: 'operationPostsPlatform', default: SOCIAL_PLATFORMS[0].id });
export const operationEntriesData = atom({ key: 'operationEntriesData', default: [] as SocialEntryInterface[] });
export const operationTicketsData = atom({ key: 'operationTicketsData', default: [] as SocialTicketInterface[] });
export const operationRole = atom({ key: 'operationRoleData', default: 'agent' as ('agent' | 'admin') });
export const operationLoadingModal = atom({
	key: 'operationLoadingModal',
	default: {
		showLoading: (() => {}) as Function,
		hideLoading: (() => {}) as Function
	}
});