import axios from "axios";
import {
  CHANGE_PAGE,
  GET_LOGIN_USER,
  GET_STAT_DATA,
  GET_DETAIL_DATA,
  SAVE_SEARCH,
  GET_PRESET,
  GET_CONFIGURATION,
  GET_DOWNLOAD_URL,
} from "./types";
// import _ from "lodash";

function deepClone(oldObject: any) {
  var newObject = JSON.parse(JSON.stringify(oldObject));
  return newObject;
}

export const changePage = (page: any) => {
  return {
    type: CHANGE_PAGE,
    payload: { currentPage: page },
  };
};

export const getLoginUser = () => async (dispatch: any) => {
  const res = await axios.get("/api/current_user");
  dispatch({ type: GET_LOGIN_USER, payload: res.data });
};

export const logout = () => async (dispatch: any) => {
  const res = await axios.get("/api/logout");
  dispatch({ type: GET_LOGIN_USER, payload: res.data });
};

export const getNonBankStatData = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  queries: any,
  query_gnames: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  dispChannels: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = dispChannels;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      queries,
      query_gnames,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      dispChannels: newDispChannels,
    };
    // console.log(payload);
    const responseStat = await axios.post("/api/getNonBankStatData", payload);
    //console.log(responseStat);
    dispatch({
      type: GET_STAT_DATA,
      payload: responseStat.data,
    });
  };
};

export const getStatData = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  lucene: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  dispChannels: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = dispChannels;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      lucene,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      dispChannels: newDispChannels,
    };
    if (payload.lucene === '("get_all")') {
      payload.lucene = "";
    }
    // console.log(payload);
    const responseStat = await axios.post("/api/getStatData", payload);
    dispatch({
      type: GET_STAT_DATA,
      payload: responseStat.data,
    });
  };
};

export const getNonBankDetailData = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  lucene: any,
  queries: any,
  query_gnames: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  sub_query: any,
  sort: any,
  overide_datetime: any,
  channel: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = channel;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      lucene,
      queries,
      query_gnames,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      sub_query,
      sort,
      overide_datetime,
      channel: newDispChannels,
    };
    if (payload.lucene === '("get_all")') {
      payload.lucene = "";
    }
    const responseDetail = await axios.post(
      "/api/getNonBankDetailData",
      payload
    );
    dispatch({
      type: GET_DETAIL_DATA,
      payload: responseDetail.data,
    });
  };
};

export const getDetailData = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  lucene: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  sub_query: any,
  sort: any,
  overide_datetime: any,
  channel: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = channel;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      lucene,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      sub_query,
      sort,
      overide_datetime,
      channel: newDispChannels,
    };
    if (payload.lucene === '("get_all")') {
      payload.lucene = "";
    }
    const responseDetail = await axios.post("/api/getDetailData", payload);
    dispatch({
      type: GET_DETAIL_DATA,
      payload: responseDetail.data,
    });
  };
};

export const saveSearch = (
  searchName: any,
  searchText: any,
  lucene: any,
  isBankSearch: any,
  color: any
) => {
  return async (dispatch: any, getState: any) => {
    let payload = {
      searchName,
      searchText,
      lucene,
      isBankSearch,
      color,
    };
    const response = await axios.post("/api/savesearch", payload);
    dispatch({
      type: SAVE_SEARCH,
      payload: response.data,
    });
  };
};

export const getPreset = () => {
  return async (dispatch: any, getState: any) => {
    const response = await axios.get("/api/getpreset");
    dispatch({
      type: GET_PRESET,
      payload: response.data,
    });
  };
};

export const deletePreset = (presetName: any) => {
  return async (dispatch: any, getState: any) => {
    const payload = { presetName: presetName };
    const response = await axios.post("/api/deletepreset", payload);
    dispatch({
      type: GET_PRESET,
      payload: response.data,
    });
  };
};

export const getConfiguration = () => {
  return async (dispatch: any, getState: any) => {
    const response = await axios.get("/api/getConfiguration");
    dispatch({
      type: GET_CONFIGURATION,
      payload: response.data,
    });
  };
};

export const saveConfiguration = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    const response = await axios.post("/api/saveconfiguration", payload);
    dispatch({
      type: GET_CONFIGURATION,
      payload: response.data,
    });
  };
};

export const saveHumanLabel = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    const response = await axios.post("/api/savehumanlabel", payload);
    let tmpData = deepClone(getState().detailData);
    const index = response.data.respondPayload.index;
    tmpData.respondPayload.result[index] = response.data.respondPayload.source;
    dispatch({
      type: GET_DETAIL_DATA,
      payload: tmpData,
    });
  };
};

export const downloadCSV = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  lucene: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  sub_query: any,
  sort: any,
  overide_datetime: any,
  channel: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = channel;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      lucene,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      sub_query,
      sort,
      overide_datetime,
      channel: newDispChannels,
    };
    if (payload.lucene === '("get_all")') {
      payload.lucene = "";
    }
    // console.log(payload);
    const responseDetail = await axios.post("/api/downloadCSV", payload);
    // console.log(responseDetail.data);
    dispatch({
      type: GET_DOWNLOAD_URL,
      payload: responseDetail.data,
    });
  };
};

export const nonBankDownloadCSV = (
  startDate: any,
  endDate: any,
  rowPerPage: any,
  pageNumber: any,
  timeFrame: any,
  keyword: any,
  include: any,
  exclude: any,
  queries: any,
  query_gnames: any,
  dispSources: any,
  dispTargets: any,
  dispSentiments: any,
  user_id: any,
  sub_query: any,
  sort: any,
  overide_datetime: any,
  channel: any
) => {
  return async (dispatch: any, getState: any) => {
    let newDispSources = dispSources;
    if (newDispSources.indexOf("ALL") !== -1) {
      newDispSources = [];
    }
    let newDispTargets = dispTargets;
    if (newDispTargets.indexOf("ALL") !== -1) {
      newDispTargets = [];
    }
    let newDispSentiments = dispSentiments;
    if (newDispSentiments.indexOf("ALL") !== -1) {
      newDispSentiments = [];
    }
    let newDispChannels = channel;
    if (newDispChannels.indexOf("ALL") !== -1) {
      newDispChannels = [];
    }
    let payload = {
      startDate,
      endDate,
      rowPerPage,
      pageNumber,
      timeFrame,
      keyword,
      include,
      exclude,
      queries,
      query_gnames,
      dispSources: newDispSources,
      dispTargets: newDispTargets,
      dispSentiments: newDispSentiments,
      user_id: user_id,
      sub_query,
      sort,
      overide_datetime,
      channel: newDispChannels,
    };
    const responseDetail = await axios.post("/api/nonBankDownloadCSV", payload);
    // console.log(responseDetail.data);
    dispatch({
      type: GET_DOWNLOAD_URL,
      payload: responseDetail.data,
    });
  };
};
