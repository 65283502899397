import React from "react";
import { connect } from "react-redux";
import { Container as BootstrapContainer, Row, Col } from "react-bootstrap";
import { Header, Icon, Divider, Segment, Button, Container } from "semantic-ui-react";
import { changePage } from "../actions";

class Home extends React.Component<any> {
  renderContent = () => (
    <Segment placeholder>
      <Container as={BootstrapContainer} text>
        <Header as="h2">Announcement</Header>
        <Divider horizontal>
          <Header as="h2">
            <Icon name="caret down" />
          </Header>
        </Divider>
        <p>2021-07-02 V.0.4.0</p>
        <ul>
          <li>Added new charts</li>
        </ul>
        <Divider></Divider>
        <p>2021-05-17 V.0.3.0</p>
        <ul>
          <li>Added new feature to support non-bank search</li>
        </ul>
        <Divider></Divider>
        <p>2020-11-05 V.0.2.0</p>
        <ul>
          <li>Optimized the speed and fixed</li>
          <li>Added channel tagging model</li>
          <li>Added SoV</li>
          <li>Added long period query</li>
        </ul>
        <ul>
          Model perf.
          <li>Sentimental Classification : F1 84.39%</li>
          <li>Topic Classification (Channel) : F1 71.11%</li>
        </ul>
        <Divider></Divider>
        <p>
          2020-05-21 V.0.1.0 We have released our first version of social
          monitoring tools!!
        </p>
        <p>The current available sources:</p>
        <ul>
          <li>Pantip</li>
          <li>Facebook</li>
          <li>Twitter</li>
        </ul>
        <Divider></Divider>
      </Container>
      <div>
        <Button
          primary
          href="#Dashboard"
          onClick={() => {
            this.props.changePage("Dashboard");
          }}
        >
          Navigate to Dashboard
        </Button>
      </div>
    </Segment>
  );

  render() {
    return (
      <Container as={BootstrapContainer} style={{ marginTop: "80px" }}>
        {/* {this.downloadPDF()}
        {this.renderInputForm()}
        {this.renderConfirmRequest()} */}
        <Row>
          <Col lg={2} md={2} xs={0} />
          <Col lg={8} md={8} xs={12}>
            <Header as="h2" icon textAlign="center">
              {/* <Icon name="street view" circular />
              <Header.Content>Innohub social monitoring tools</Header.Content> */}
              <img
                src="/image/1909978.jpg"
                alt="InnoHub"
                style={{
                  width: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Header>
            {this.renderContent()}
          </Col>
          <Col lg={2} md={2} xs={0} />
        </Row>
      </Container>
    );
  }
}

export default connect(null, { changePage })(Home);
