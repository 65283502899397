import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
} from "recharts";

import { Card, Label, Icon, Tab, Grid, Dropdown, Header } from "semantic-ui-react";
import { Container, Row } from "react-bootstrap";

class OverallMentionCat extends React.Component<any> {
  panes = [
    { menuItem: 'By Sentiment', render: () => <Tab.Pane>{this.tab1_renderBarChart_by_sentiment()}</Tab.Pane> },
    { menuItem: 'By Bank', render: () => <Tab.Pane>{this.tab2_renderBarChart_by_bank()}</Tab.Pane> },
    { menuItem: 'By Bank and Sentiment', render: () => <Tab.Pane>{this.tab3_renderBarChart_by_bank_by_sentiment()}</Tab.Pane> },
  ]
  state = { currentTab: 0, isShowSuggest: false, data: this.props.data, tab3ActiveBank: 0 };
  [key: string]: any;
  componentDidMount() {
    this.ref = React.createRef();
    this.setState({ data: this.props.data });
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      this.setState({ data: this.props.data });
    }
  }
  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderTooltip(props: any) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      // console.log(data);
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            padding: 10,
          }}
        >
          <p>{data.class}</p>
          <p>
            <span>Bank: </span>
            {data.bank}
          </p>
          <p>
            <span>Value: </span>
            {data.value}
          </p>
        </div>
      );
    }
    return null;
  }

  renderCustomAxisTick = ({ payload, x, y, width, height, value }: any) => {
    const textSplit = payload.value.split("->");
    return <text x={x - 250} y={y + 5} fill="#666" textAnchor="left" dy={0}>{`${textSplit[1]} | ${textSplit[2]}`}</text>;
  }

  renderBarChart_by_bank_by_sentiment = (bank: any, data: any) => {
    return (
      <BarChart
        layout="vertical"
        width={900}
        height={800}
        data={data}
        margin={{ top: 0, right: 0, left: 200, bottom: 10 }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: [bank],
              sentiment: this.props.dispSentiments,
              category: [param.activeLabel + "<AND>"],
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" tick={this.renderCustomAxisTick} />
        <Tooltip />
        <Legend />
        {this.renderBar_by_bank_by_sentiment(data[0])}
      </BarChart>
    );
  };

  renderBar_by_bank_by_sentiment(data: any) {
    const barObj = Object.keys(data).map((key, idx) => (
      <Bar
        key={`key_${idx}`}
        dataKey={key}
        stackId="a"
        fill={this.sentiment_color[key.toLowerCase()]}
      />
    ));
    const barObjFiltered = barObj.filter((ele) => ele.props.dataKey !== "name");
    return barObjFiltered;
  }

  tab3_renderBarChart_by_bank_by_sentiment = () => {
    const option = this.state.data.cat_bank_sen["channel"].map((ele: any) => ({ key: ele.name, text: ele.name.toUpperCase(), value: ele.name }))
    let bank = option[this.state.tab3ActiveBank].text
    return (
      <div style={{ margin: "auto" }}>
        <Dropdown
          placeholder='State'
          fluid
          selection
          options={option}
          text={option[this.state.tab3ActiveBank].text}
          onChange={(event, data: any) => {
            const selected: string = data.value
            let selectedIdx = 0
            option.forEach((ele: any, idx: any) => {
              if (ele.value === selected) { selectedIdx = idx }
            })
            this.setState({ tab3ActiveBank: selectedIdx })
          }}
        />
        <Header as='h2' style={{ marginTop: "20px" }}>{option[this.state.tab3ActiveBank].text}</Header>
        <div style={{ marginTop: "10px" }}>{this.renderBarChart_by_bank_by_sentiment(bank, this.state.data.cat_bank_sen["channel"][this.state.tab3ActiveBank].data)}</div>
      </div>
    );
  };

  renderBarChart_by_bank = (data: any) => {
    return (
      <BarChart
        layout="vertical"
        width={900}
        height={800}
        data={data}
        margin={{ top: 0, right: 0, left: 200, bottom: 10 }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: this.props.dispTargets,
              sentiment: this.props.dispSentiments,
              category: [param.activeLabel + "<AND>"],
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" tick={this.renderCustomAxisTick} />
        <Tooltip />
        <Legend />
        {this.renderBar_by_bank(data[0])}
      </BarChart>
    );
  };

  renderBar_by_bank(data: any) {
    const barObj = Object.keys(data).map((key, idx) => (
      <Bar
        key={`key_${idx}`}
        dataKey={key}
        stackId="a"
        fill={this.props.colors[key.toLowerCase()]}
      />
    ));
    const barObjFiltered = barObj.filter((ele) => ele.props.dataKey !== "name");
    return barObjFiltered;
  }

  tab2_renderBarChart_by_bank = () => {
    return (
      <div style={{ margin: "auto" }}>
        <Header as='h2' style={{ marginTop: "20px" }}>Total</Header>
        <div>{this.renderBarChart_by_bank(this.state.data.cat["channel"])}</div>
      </div>
    );
  };


  renderBarChart_by_sentiment = (data: any) => {
    return (
      <BarChart
        layout="vertical"
        width={900}
        height={800}
        data={data}
        margin={{ top: 0, right: 0, left: 200, bottom: 10 }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: this.props.dispTargets,
              sentiment: this.props.dispSentiments,
              category: [param.activeLabel + "<AND>"],
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" tick={this.renderCustomAxisTick} />
        <Tooltip />
        <Legend />
        {this.renderBar_by_sentiment(data[0])}
      </BarChart>
    );
  };

  sentiment_color: any = {
    positive: "#34a321",
    negative: "#b3072c",
    neutral: "#7d7b72",
  }

  renderBar_by_sentiment(data: any) {
    const barObj = Object.keys(data).map((key, idx) => (
      <Bar
        key={`key_${idx}`}
        dataKey={key}
        stackId="a"
        fill={this.sentiment_color[key.toLowerCase()]}
      />
    ));
    const barObjFiltered = barObj.filter((ele) => ele.props.dataKey !== "name");
    return barObjFiltered;
  }

  tab1_renderBarChart_by_sentiment = () => {
    return (
      <div style={{ margin: "auto" }}>
        <Header as='h2' style={{ marginTop: "20px" }}>Total</Header>
        <div>{this.renderBarChart_by_sentiment(this.state.data.cat_sen["channel"])}</div>
      </div>
    );
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Overall Channel Category
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(
                    this.ref,
                    "overAllBankMentionCategory.jpeg",
                    "#FFFFFF"
                  );
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>
            Overall mention count in channel category each bank
          </Card.Meta>
        </Card.Content>
        <Card.Content>
          <Container ref={this.ref}>
            <Row>
              <Tab
                panes={this.panes}
                activeIndex={this.state.currentTab}
                renderActiveOnly={true}
                onTabChange={(event: any, data: any) => {
                  this.setState({ currentTab: data.activeIndex });
                }}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                style={{ width: "100%" }}
              /></Row>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}

export default OverallMentionCat;
