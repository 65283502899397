import React, { useEffect, useLayoutEffect, useState } from "react";

// Components
import { Button, Container, Dropdown, Header, Icon, Modal, Segment, Tab } from "semantic-ui-react";
import { ViewPosts as Posts } from "./operation-posts";
import { ViewAgents as Agents } from "./operation-agents";

// 3rd-Party Actions (Custom Hooks, States, etc.)
import { connect } from "react-redux";
import { RecoilRoot, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { changePage } from "../../actions";
import { stackAtomPop } from "../utils/recoil-helpers";
import { operationRole } from "../recoil-atoms/operation";
import {
	operationActionModalStack as actionModalStack,
	operationActionDataStack as actionDataStack,
	operationLoadingModal
} from '../recoil-atoms/operation';

// Styles
import '../css/operation.css';

const OperationRoleSelect = () => {
	// TODO: getRole endpoint?
	const [role, setRole] = useRecoilState(operationRole);

	const selectRole = (role: 'agent' | 'admin') => setRole(role);

	return (
		<Segment className="operation-role-select" basic>
		<Dropdown icon="user" text={role} direction="left">
			<Dropdown.Menu>
				<Dropdown.Header content="Select Role"/>
				<Dropdown.Item text="Agent" value="agent" onClick={() => selectRole("agent")}/>
				<Dropdown.Item text="Admin" value="admin" onClick={() => selectRole("admin")}/>
			</Dropdown.Menu>
		</Dropdown></Segment>
	);
};

const OperationMainTab = () => {
	const [panes, setPanes] = useState<NodeJS.Dict<any>[]>([]);
	const role = useRecoilValue(operationRole);

	useLayoutEffect(() => {
		let mainPanes = [
			{
				menuItem: {
					key: 'posts',
					content: (
						<div>
							<Icon name="clone"/>
							<span> Posts</span>
						</div>
					)
				},
				render: () => <Posts/>
			}
		];
		if (role === 'admin')
			mainPanes.push(
				{
					menuItem: {
						key: 'Agents',
						content: (
							<div>
								<Icon name="users"/>
								<span> Agents</span>
							</div>
						)
					},
					render: () => <Agents/>
				}
			)
		setPanes(mainPanes);
	}, [role]);

	return (
		<Tab menu={{
			className: 'segment-tab',
			hidden: role === 'agent',
			secondary: true,
			pointing: true,
			widths: panes.length ? panes.length : undefined
		}} panes={panes}/>
	)
};

const OperationActionModal = () => {
	const [modalStack, setModalStack] = useRecoilState(actionModalStack);
	const [dataStack, setDataStack] = useRecoilState(actionDataStack);

	const clearAllOperationStacks = () => {
		stackAtomPop(modalStack, setModalStack, 'all');
		stackAtomPop(dataStack, setDataStack, 'all');
	};
	const popAllOperationStacks = () => {
		stackAtomPop(modalStack, setModalStack);
		stackAtomPop(dataStack, setDataStack);
	};

	return (
		<Modal
			open={modalStack.length ? true : false}
			closeOnDimmerClick={false}
		>
			<Modal.Header>{modalStack.length && modalStack[modalStack.length - 1].header}</Modal.Header>
			<Modal.Content>{modalStack.length && modalStack[modalStack.length - 1].content}</Modal.Content>
			<Modal.Actions>
				<Button onClick={clearAllOperationStacks} floated="left" color="red">Close</Button>
				<Button onClick={popAllOperationStacks} floated="left" hidden={modalStack.length > 1 ? false : true}>Back</Button>
				<Button disabled className="padding-placeholder">.</Button>
				{modalStack.length && modalStack[modalStack.length - 1].actions}
			</Modal.Actions>
		</Modal>
	);
};

const OperationMainPage = (props: {
	showLoading: Function,
	hideLoading: Function
}) => {

	// Passing handlers to the loading modal custom hook
	// Move this to the root page once the migration is complete
	// Otherwise, the loading modal will not trigger on operation page initialization
	const setLoadingModalHandlers = useSetRecoilState(operationLoadingModal);

	useLayoutEffect(() => {
		setLoadingModalHandlers({
			showLoading: props.showLoading,
			hideLoading: props.hideLoading
		});
	}, []);

	return (
		<>
			{/* <OperationRoleSelect/> */}
			<Segment className="operation">
				<OperationMainTab/>
				<OperationActionModal/>
			</Segment>
		</>
	);
};

// container class component wrap + redux adaptation
class Operation extends React.Component<any> {
	render = () => {
		return (
			<RecoilRoot>
				<Container /*fluid*/ className="operation page-content">
					<OperationMainPage
						showLoading={this.props.showLoading as Function}
						hideLoading={this.props.hideLoading as Function}
					/>
				</Container>
			</RecoilRoot>
		);
	};
};

export default connect(null, { changePage })(Operation);